import {ClassNames as Constants, ClassNames, DataSets, DomIds, ElementLengths, NodeNames} from '../constants/Constants';
import DocumentSelector from '../helper/DocumentSelector';
import Cassette from './Cassette';
import ColorHelper from '../helper/ColorHelper';
import WallElement from './WallElement';
import PillarsElement from './PillarsElement';
import FreeWallElement from './FreeWallElement';
import DecorElement from './DecorElement';
import BrowserCheck from '../helper/BrowserCheck';
import Ajax from './Ajax';
import DoorElement from './DoorElement';
import DoorHandleHelper from '../helper/DoorHandleHelper';
import FreeDoorElement from './FreeDoorElement';
import ImageHelper from '../helper/ImageHelper';
import AbschlussElement from './AbschlussElement';
import UrlHelper from '../helper/UrlHelper';
import KeramikElement from './KeramikElement';
import documentSelector from '../helper/DocumentSelector';

class Wall {

    constructor() {
        this.elements = [];
        this.relevantTable = 0;
        this.relevantTablePosition = 0;
        this.pillars = [];
        this.commonLength = 7;
        this.pillarsColor = '';
        this.profileColor = 'eloxiert';

        this.setPresentationFieldWidth();
    }

    addElementFromColorSelection() {
        const finishedWallNodes = document.getElementsByClassName(DomIds.CASSETTE_ITEMS);

        if (finishedWallNodes) {
            for (let i = 0; i < finishedWallNodes.length; i++) {
                const children = this.getElementChildren(finishedWallNodes[i]);
                const childrenLength = children.length;
                const element = new WallElement(finishedWallNodes[i].className);
                element.isDoor = finishedWallNodes[i].dataset.door === '1';
                element.isDoubleDoor = finishedWallNodes[i].className.includes(Constants.DOUBLE_DOOR);
                element.handlePosition = finishedWallNodes[i].dataset.handleposition;
                for (let i = 0; i < childrenLength; i++) {
                    const childNode = children[i];
                    if (childNode.className !== 'over') {
                        const color = ColorHelper.findColorOfCassette(childNode);
                        const visibility = Cassette.getVisibilityClass(childNode);
                        const pos = i + 1;
                        const cassette = new Cassette(pos, color, visibility);
                        element.addCassette(cassette);
                    }
                }

                let activeIndex = finishedWallNodes[i].getAttribute('data-number');
                this.elements[activeIndex] = element;
            }
        } else {
            this.elements.splice(this.relevantTable + 1, 0, this.element);
        }

        this.setPresentationFieldWidth();
    }

    getElementChildren(finishedNode) {
        const children = [];
        const childrenLength = finishedNode.children.length;
        for (let i = 0; i < childrenLength; i++) {
            const child = finishedNode.children[i];
            if (!child.classList.contains('door-overlay')) {
                children.push(child);
            }
        }

        return children;
    }

    copyCurrentElement() {
        const finishedWallNodes = document.getElementsByClassName(DomIds.CASSETTE_ITEMS);
        let copyElement = false;
        const numberOfDoorCassette = 8;
        if (finishedWallNodes) {
            for (let i = this.elements.length - 1; i > this.relevantTable; i--) {
                this.elements[i + 1] = this.elements[i];
            }

            for (let i = 0; i < finishedWallNodes.length; i++) {
                const children = finishedWallNodes[i].children;
                let childrenLength = children.length;

                const isDoor = finishedWallNodes[i].dataset[DataSets.DOOR] === '1';
                let element;
                if (isDoor) {
                    const handlePosition = finishedWallNodes[i].dataset[DataSets.HANDLE_POSITION];
                    element = new DoorElement(finishedWallNodes[i].className, handlePosition);
                    childrenLength -= numberOfDoorCassette;
                } else {
                    element = new WallElement(finishedWallNodes[i].className);
                }


                for (let i = 0; i < childrenLength; i++) {
                    const childNode = children[i];
                    if (childNode.className !== 'over' && childNode.className !== 'door-overlay') {
                        const color = ColorHelper.findColorOfCassette(childNode);
                        const visibility = Cassette.getVisibilityClass(childNode);
                        const pos = i + 1;
                        const cassette = new Cassette(pos, color, visibility);
                        element.addCassette(cassette);
                    }
                }
                let activeIndex = '';

                if (copyElement === false) {
                    activeIndex = parseInt(finishedWallNodes[i].getAttribute('data-number'));
                } else {
                    activeIndex = parseInt(finishedWallNodes[i].getAttribute('data-number')) + 1;
                }

                this.elements[activeIndex] = element;
                if (activeIndex === this.relevantTable) {
                    this.elements[this.relevantTable + 1] = JSON.parse(JSON.stringify(element));
                    copyElement = true;
                }


            }
            let currentElement = this.elements[this.relevantTable];
            let currentLength = this.getElementsLength(currentElement) + ElementLengths.ELEMENT_BETWEEN_DISTANCE;
            this.commonLength += currentLength;
        }
        this.setPresentationFieldWidth();
    }

    getElementsLength(element) {
        let length = 0;

        if (element.hasOwnProperty('isDoor') && element.isDoor === 'door') {
            length = ElementLengths.DOOR_LENGTH;
        } else {
            let elementClass = element.className;
            let classes = elementClass.split(' ');
            classes.forEach((element) => {
                if (element.indexOf('length-') !== -1) {
                    let lenghtClass = element.split('-');
                    length = parseInt(lenghtClass[1]);
                }
            });
        }


        return length;
    }

    copyCurrentDecorElement() {
        const decorWallNodes = document.getElementsByClassName(DomIds.DECOR_ITEMS);
        if (decorWallNodes) {
            for (let i = this.elements.length - 1; i > this.relevantTable; i--) {
                this.elements[i + 1] = this.elements[i];
            }

            for (let i = 0; i < decorWallNodes.length; i++) {
                let activeIndex = parseInt(decorWallNodes[i].getAttribute('data-number'));

                if (activeIndex === this.relevantTable) {
                    this.elements[this.relevantTable + 1] = this.elements[activeIndex];
                }
            }
        }
        this.commonLength += ElementLengths.DECOR_LENGTH;
        this.setPresentationFieldWidth();
    }

    copyCurrentKeramikElement() {
        const keramikWallNodes = document.getElementsByClassName(ClassNames.KERAMIK_ITEM);
        if (keramikWallNodes) {
            for (let i = this.elements.length - 1; i > this.relevantTable; i--) {
                this.elements[i + 1] = this.elements[i];
            }

            for (let i = 0; i < keramikWallNodes.length; i++) {
                let activeIndex = parseInt(keramikWallNodes[i].getAttribute('data-number'));
                if (activeIndex === this.relevantTable) {
                    this.elements[this.relevantTable + 1] = this.elements[activeIndex];
                }
            }
        }
        let currentElement = this.elements[this.relevantTable];
        let currentLength = this.getElementsLength(currentElement) + ElementLengths.ELEMENT_BETWEEN_DISTANCE;
        this.commonLength += currentLength;
        this.setPresentationFieldWidth();
    }

    addElementFromSelectedType(typeElements, standardElementType, isDoor, isSmallFence, isSmallType = null, isDoubleDoor = null, index = 0) {
        let children = [];
        if (BrowserCheck.isBrowserIE()) {
            children = BrowserCheck.objectValueForBrowserIE(typeElements);
        } else {
            children = Object.values(typeElements);
        }

        const elementsLength = children.length;
        let objectClass = '';
        if (standardElementType === '') {
            objectClass = 'wall length-' + typeElements.elementLength + ' ' + Constants.HIGH_FENCE;
        } else {
            objectClass = 'wall length-' + typeElements.elementLength + ' standard-element-' + standardElementType + ' ' + Constants.HIGH_FENCE;
        }

        if (isSmallFence) {
            objectClass = 'wall length-' + typeElements.elementLength + ' smallfence-' + standardElementType;
            if (standardElementType !== '') {
                objectClass += ' smallfence-standard-' + standardElementType;
            }
        }
        if (isSmallType) {
            objectClass = 'wall length-' + typeElements.elementLength + ' smallfence-type' + standardElementType;
        }

        let isNotStandardElement = false;
        const selectedProfileColorNode = document.querySelector('input[name="' + NodeNames.PROFILE_COLOR + '"]:checked');
        if (selectedProfileColorNode && isSmallFence) {
            const selectedProfileColor = selectedProfileColorNode.dataset[DataSets.PROFILE_COLOR];
            if (selectedProfileColor === 'stonegrey' || selectedProfileColor === 'anthrazit') {
                objectClass = 'small-fence wall length-' + typeElements.elementLength + ' smallfence-type';
                isNotStandardElement = true;
            }
        } else if(selectedProfileColorNode) {
            const selectedProfileColor = selectedProfileColorNode.dataset[DataSets.PROFILE_COLOR];
            if (selectedProfileColor === 'stonegrey' || selectedProfileColor === 'anthrazit') {
                objectClass = 'wall length-' + typeElements.elementLength + ' ' + Constants.HIGH_FENCE;
                isNotStandardElement = true;
            }
        }

        let element = '';
        if (isDoor) {
            if (typeElements.editable === false) {
                element = new DoorElement(objectClass);
            } else {
                element = new FreeDoorElement(objectClass);
            }
        } else if (isDoubleDoor) {
            objectClass = DoorElement.checkDoubleDoorDirection(objectClass, index);
            if (index === 1) {
                this.relevantTable += 1;
            }
            if (typeElements.editable === false) {
                element = new DoorElement(objectClass);
            } else {
                element = new FreeDoorElement(objectClass);
            }
        } else {
            if (typeElements.editable === false) {
                element = new WallElement(objectClass);
            } else {
                element = new FreeWallElement(objectClass, isSmallFence);
            }
        }


        for (let i = 0; i < elementsLength; i++) {
            if (typeof children[i] === 'string' && !children[i].includes('http')) {
                let color = '';
                let visibility = children[i];
                if (isNotStandardElement) {
                    const classArray = children[i].split(' ');
                    color = 1 in classArray ? classArray[1] : '';
                    visibility = classArray[0];
                }

                const pos = i + 1;
                const cassette = new Cassette(pos, color, visibility);
                element.addCassette(cassette);
            }
        }
        let elementLength = typeElements.elementLength;
        if (isDoor) {
            elementLength = ElementLengths.DOOR_LENGTH;
        }
        this.elements.splice(this.relevantTable + 1, 0, element);
        this.commonLength += elementLength + ElementLengths.ELEMENT_BETWEEN_DISTANCE;
        this.setPresentationFieldWidth();
    }


    addDecorFromSelectedType(decorElementsPath) {
        const element = new DecorElement(decorElementsPath);
        element.decorPath[0] = decorElementsPath;
        this.elements.splice(this.relevantTable + 1, 0, element);
        this.commonLength += ElementLengths.DECOR_LENGTH;

        this.setPresentationFieldWidth();
    }

    addKeramikFromSelectedType(keramikElementsPath, length) {
        const element = new KeramikElement(keramikElementsPath);
        element.keramikPath[0] = keramikElementsPath;
        element.className = 'keramik-item ' + length;
        this.elements.splice(this.relevantTable + 1, 0, element);
        if (length === 'length-90') {
            this.commonLength += 90 + ElementLengths.ELEMENT_BETWEEN_DISTANCE;
        } else {
            this.commonLength += 180 + ElementLengths.ELEMENT_BETWEEN_DISTANCE;
        }

        this.setPresentationFieldWidth();
    }

    addAbschlussFromSelectedType(abschlussElementsPath) {
        const element = new AbschlussElement(abschlussElementsPath);
        this.elements.splice(this.relevantTable + 1, 0, element);
        this.commonLength += ElementLengths.ABSCHLUSS_LENGTH;

        this.setPresentationFieldWidth();
    }

    addCurrentDecorElement(tableNumber) {
        let decorElementsPath = '';

        const DecorElements = DocumentSelector.selectAllByClass('decor-item');
        DecorElements.forEach((element) => {
            if (element.getAttribute('data-number') === tableNumber) {
                let DecorElementItems = element.children;
                if (DecorElementItems) {
                    decorElementsPath = DecorElementItems[0].currentSrc;
                }
            }
        });

        const element = new DecorElement(decorElementsPath);
        element.decorPath[0] = decorElementsPath;
        this.elements.splice(this.relevantTable + 1, 0, element);
        this.setPresentationFieldWidth();

    }

    deleteElementWithId(elementId) {
        const tableElement = document.querySelectorAll('[data-number="' + elementId + '"]');
        if (tableElement.length > 0) {
            let elementLength = 0;
            if (tableElement[0].classList.contains('table-element')) {
                const isDoor = tableElement[0].dataset.door === '1';
                if (!isDoor) {
                    elementLength = this.getElementsLength(tableElement[0]) + ElementLengths.ELEMENT_BETWEEN_DISTANCE;
                } else {
                    elementLength = 106;
                }
            } else if (tableElement[0].classList.contains('decor-item')) {
                elementLength = ElementLengths.DECOR_LENGTH;
            }

            this.commonLength -= elementLength;
        }

        const toDeleteTable = DocumentSelector.selectById('element-table-' + elementId);
        const toDeleteArticleNode = toDeleteTable.querySelector('article');


        if (BrowserCheck.isBrowserIE()) {
            toDeleteTable.parentNode.removeChild(toDeleteTable);
        } else {
            toDeleteTable.remove();
        }

        this.elements.splice(elementId, 1);

        if (toDeleteArticleNode) {
            const isDoubleDoorLeft = toDeleteArticleNode.classList.contains(ClassNames.DOUBLE_DOOR_LEFT);
            if (isDoubleDoorLeft) {
                const doubleDoorRightId = elementId + 1;
                const toDeleteRightDoubleDoor = DocumentSelector.selectById('element-table-' + doubleDoorRightId);
                toDeleteRightDoubleDoor.remove();
                this.elements.splice(elementId, 1);
            }
        }


        this.setPresentationFieldWidth();
    }

    changeKeramikLength(e) {
        let length = e.target.dataset.length;
        let activeTable1 = document.getElementsByClassName(ClassNames.ACTIVE);
        if (activeTable1.length > 0) {
            const imgNode = activeTable1[0].getElementsByTagName('img');
            if (parseInt(length) === 90) {
                let oldSrc = imgNode[0].currentSrc;
                oldSrc = oldSrc.replace('180', '90');
                imgNode[0].src = oldSrc;
                if (activeTable1[0].classList.contains('length-180')) {
                    activeTable1[0].classList.remove('length-180');
                    activeTable1[0].classList.add('length-90');
                }
                let diff = parseInt('180') - parseInt(length);
                this.commonLength -= diff;
                let lengthTitle = DocumentSelector.selectById('total-wall-length');
                if (lengthTitle) {
                    lengthTitle.innerHTML = this.commonLength;
                }
            } else {
                let oldSrc = imgNode[0].currentSrc;
                oldSrc = oldSrc.replace('90', '180');
                imgNode[0].src = oldSrc;
                if (activeTable1[0].classList.contains('length-90')) {
                    activeTable1[0].classList.remove('length-90');
                    activeTable1[0].classList.add('length-180');
                }
                let diff1 = parseInt('90') - parseInt(length);
                this.commonLength -= diff1;
                let lengthTitle = DocumentSelector.selectById('total-wall-length');
                if (lengthTitle) {
                    lengthTitle.innerHTML = this.commonLength;
                }
            }
            let tableNumber = 0;
            let elementTableNumber = activeTable1[0].getAttribute('data-number');
            if (parseInt(elementTableNumber) <= parseInt(this.relevantTable) || parseInt(elementTableNumber) === parseInt(this.relevantTable) + 1) {
                tableNumber = elementTableNumber;
            }
            const element = this.elements[tableNumber];
            if (element) {
                element.keramikPath[0] = imgNode[0].src;
                element.className = 'keramik-item length-' + length;
            }
            this.savePillarsInputs();
            this.save();
        }
    }

    enableActiveClass(targetElement) {
        let allElements = DocumentSelector.selectAllByClass('element-article');
        allElements.forEach((element) => {
            if (element.classList.contains(ClassNames.ACTIVE)) {
                element.classList.remove(ClassNames.ACTIVE);
            }
        });
        targetElement.classList.add(ClassNames.ACTIVE);
    }

    changeCassetteLength(e) {

        let length = e.target.dataset.length;
        let tableNumber = 0;

        let activeTable = DocumentSelector.selectAllByClass(ClassNames.WALL_ELEMENT_ACTIVE);

        if (activeTable.length > 0) {
            let activeTableId = activeTable[0].getAttribute('id');
            const idParts = activeTableId.split('-');
            tableNumber = idParts[idParts.length - 1];
        } else {
            let elements = DocumentSelector.selectAllByClass(DomIds.CASSETTE_ITEMS);
            if (elements.length > 0) {
                BrowserCheck.forEachForBrowserIE();
                elements.forEach((element) => {
                    let elementTableNumber = element.getAttribute('data-number');

                    if (parseInt(elementTableNumber) <= parseInt(this.relevantTable) || parseInt(elementTableNumber) === parseInt(this.relevantTable) + 1) {
                        tableNumber = elementTableNumber;
                    }
                });
            }
        }

        let oldLengthClass = '';
        let classesNames = '';

        let currentTable = document.querySelector('[data-number="' + tableNumber + '"]');

        if (currentTable !== '') {
            classesNames = currentTable.className;

            let classes = classesNames.split(' ');
            for (let classItem of classes) {
                if (classItem.search('length-') === 0) {
                    oldLengthClass = classItem;
                }
            }

            const oldLength = oldLengthClass.split('-');
            let diff = parseInt(oldLength[1]) - parseInt(length);
            this.commonLength -= diff;
            let lengthTitle = DocumentSelector.selectById('total-wall-length');
            if (lengthTitle) {
                lengthTitle.innerHTML = this.commonLength;
            }

            let newClass = currentTable.className.replace(oldLengthClass, 'length-' + length);
            currentTable.className = newClass;
            const element = this.elements[tableNumber];
            if (element) {
                element.className = newClass;
            }
        }
    }

    setTableNumberOfActiveElement() {
        let tableNumber = 0;
        let currentTable = '';
        let activeTable = DocumentSelector.selectAllByClass(ClassNames.WALL_ELEMENT_ACTIVE);
        let elements = DocumentSelector.selectAllByClass(ClassNames.HIGH_FENCE);

        if (activeTable.length > 0) {
            let activeTableId = activeTable[0].getAttribute('id');
            tableNumber = activeTableId.substr(activeTableId.length - 1, activeTableId.length);
        } else {
            if (elements.length > 0) {
                BrowserCheck.forEachForBrowserIE();
                elements.forEach((element) => {
                    let elementTableNumber = element.getAttribute('data-number');

                    if (parseInt(elementTableNumber) <= parseInt(this.relevantTable) || parseInt(elementTableNumber) === parseInt(this.relevantTable) + 1) {
                        tableNumber = elementTableNumber;
                    }
                });
            }
        }
        currentTable = document.querySelector('[data-number="' + tableNumber + '"]');
        return currentTable;
    }

    makeRelevantLengthButtonActive() {
        const activeTable = this.setTableNumberOfActiveElement();
        let lengthButton;
        if (activeTable.classList.contains('length-180')) {
            lengthButton = DocumentSelector.selectById(DomIds.LENGTH_180);
        } else if (activeTable.classList.contains('length-90')) {
            lengthButton = DocumentSelector.selectById(DomIds.LENGTH_90);
        }
        if (lengthButton) {
            lengthButton.checked = true;
        }

    }

    saveElements() {
        const finishedWallNodes = document.getElementsByClassName(DomIds.ELEMENT_ARTICLE);
        this.commonLength = 7;

        for (let i = 0; i < finishedWallNodes.length; i++) {
            const isDoor = finishedWallNodes[i].dataset[DataSets.DOOR] === '1';
            const isDecor = finishedWallNodes[i].classList.contains(DomIds.DECOR_ITEMS);
            const isAbschluss = finishedWallNodes[i].classList.contains(ClassNames.ABSCHLUSS_ITEM);
            const isKeramik = finishedWallNodes[i].classList.contains(ClassNames.KERAMIK_ITEM);
            const children = finishedWallNodes[i].children;
            const childrenLength = children.length;
            let element;
            if (isDoor) {
                const handlePosition = finishedWallNodes[i].dataset[DataSets.HANDLE_POSITION];
                element = new DoorElement(finishedWallNodes[i].className, handlePosition);
            } else if (isDecor) {
                const image = finishedWallNodes[i].querySelector('img');
                const decorElementsPath = image.src;
                element = new DecorElement(decorElementsPath);
                element.decorPath[0] = decorElementsPath;
                element.className = finishedWallNodes[i].className;
            } else if (isAbschluss) {
                const image = finishedWallNodes[i].querySelector('img');
                const abschlussElementsPath = image.src;
                element = new AbschlussElement(abschlussElementsPath);
                element.className = finishedWallNodes[i].className;
            } else if (isKeramik) {
                const image = finishedWallNodes[i].querySelector('img');
                const keramikElementsPath = image.src;
                element = new KeramikElement();
                element.keramikPath[0] = keramikElementsPath;
                element.className = finishedWallNodes[i].className;
            } else {
                element = new WallElement(finishedWallNodes[i].className);
            }

            if (!isDecor && !isAbschluss) {
                for (let j = 0; j < childrenLength; j++) {
                    const childNode = children[j];
                    if (childNode.className !== 'over') {
                        if (
                            childNode.classList.contains(ClassNames.WALL_ELEMENT)
                            || childNode.classList.contains(ClassNames.WALL_ELEMENT_SMALL)
                        ) {
                            const color = ColorHelper.findColorOfCassette(childNode);
                            const visibility = Cassette.getVisibilityClass(childNode);
                            const pos = j + 1;
                            const cassette = new Cassette(pos, color, visibility);
                            element.addCassette(cassette);
                        }
                    }
                }
            }

            let activeIndex = '';

            activeIndex = parseInt(finishedWallNodes[i].getAttribute('data-number'));

            this.elements[activeIndex] = element;

            let currentElement = this.elements[this.relevantTable];
            let currentLength = this.getElementsLength(currentElement) + ElementLengths.ELEMENT_BETWEEN_DISTANCE;
            this.commonLength += currentLength;
        }
    }

    removeStandardElementClasses() {
        const selectedProfileColorNode = document.querySelector('input[name="' + NodeNames.PROFILE_COLOR + '"]:checked');
        if (selectedProfileColorNode) {
            const selectedProfileColor = selectedProfileColorNode.dataset[DataSets.PROFILE_COLOR];
            if (selectedProfileColor === 'stonegrey' || selectedProfileColor === 'anthrazit') {
                const finishedWallNodes = document.getElementsByClassName(DomIds.ELEMENT_ARTICLE);
                for (let i = 0; i < finishedWallNodes.length; i++) {
                    const element = finishedWallNodes[i];
                    element.classList.forEach((item) => {
                        if (item.includes(ClassNames.STANDARD_ELEMENT)) {
                            element.classList.remove(item);
                        }
                    });
                }
            }
        }

    }

    savePillarsInputs() {
        let pillarsElement = new PillarsElement;
        let pillarColor = document.getElementsByName('postColor');
        BrowserCheck.forEachForBrowserIE();
        pillarColor.forEach((element) => {
            if (element.checked === true) {
                let currentColor = element.getAttribute('value');
                this.setPillarColor(currentColor);
                pillarsElement.addColor(element.id);
            }
        });

        let pillarAnchor = document.getElementsByName('postAnchor');
        pillarAnchor.forEach((element) => {
            if (element.checked === true) {
                pillarsElement.addAnchor(element.id);
            }
        });

        let pillarAccessory = document.getElementById('coveringCaps');
        if (pillarAccessory.checked === true) {
            pillarsElement.addAccessory(true);
        } else {
            pillarsElement.addAccessory(false);
        }

        this.pillars = pillarsElement;
    }

    setPillarColor(color) {
        let pillarImage = DocumentSelector.selectAllByClass('decor-item');
        let allElements = DocumentSelector.selectAllByClass('element-article');
        let post2 = DocumentSelector.selectAllByClass('post-2');
        let pillarColor = '';
        const length_180 = 'length-180';
        const length_90 = 'length-90';

        if (post2.length > 0) {
            pillarColor = DocumentSelector.selectAllByClass('post-2');
        } else {
            pillarColor = DocumentSelector.selectAllByClass('post');
        }
        this.pillarsColor = color;

        const baseUrl = DocumentSelector.selectById('baseUrl').innerHTML;

        // new code for  setpillars
        allElements.forEach((element, index) => {

            if (color === 'anthrazit') {
                if (allElements.length > 0) {
                    if (element.classList.contains(length_180)) {
                        if (element.classList.contains(Constants.SMALL_FENCE)) {
                            let nextElement = '';
                            if (allElements[index + 1] !== undefined) {
                                nextElement = allElements[index + 1];
                            }
                            if (nextElement) {
                                if (nextElement.classList.contains(Constants.HIGH_FENCE) || nextElement.classList.contains(DomIds.DECOR_ITEMS)) {
                                    element.style.backgroundImage = 'url(' + baseUrl + 'assets/images/element-bilder/planerhintergrund-180-smallbig-anthrazit.svg)';
                                } else {
                                    element.style.backgroundImage = 'url(' + baseUrl + 'assets/images/element-bilder/planerhintergrund-180-small-anthrazit.svg)';
                                }
                            } else {
                                element.style.backgroundImage = 'url(' + baseUrl + 'assets/images/element-bilder/planerhintergrund-180-small-anthrazit.svg)';
                            }
                        } else {
                            element.style.backgroundImage = 'url(' + baseUrl + 'assets/images/element-bilder/planerhintergrund-180-anthrazit.svg)';
                        }
                        element.style.backgroundRepeat = 'no-repeat';
                    }
                }

                if (element.classList.contains(length_90)) {
                    if (element.classList.contains(Constants.SMALL_FENCE)) {
                        let nextElement = '';
                        if (allElements[index + 1] !== undefined) {
                            nextElement = allElements[index + 1];
                        }
                        if (nextElement) {
                            if (nextElement.classList.contains(Constants.HIGH_FENCE) || nextElement.classList.contains(DomIds.DECOR_ITEMS)) {
                                element.style.backgroundImage = 'url(' + baseUrl + 'assets/images/element-bilder/planerhintergrund-90-smallbig-anthrazit.svg)';
                            } else {
                                element.style.backgroundImage = 'url(' + baseUrl + 'assets/images/element-bilder/planerhintergrund-90-small-anthrazit.svg)';
                            }
                        } else {
                            element.style.backgroundImage = 'url(' + baseUrl + 'assets/images/element-bilder/planerhintergrund-90-small-anthrazit.svg)';
                        }
                    } else {
                        element.style.backgroundImage = 'url(' + baseUrl + 'assets/images/element-bilder/planerhintergrund-90-anthrazit.svg)';
                    }
                    element.style.backgroundRepeat = 'no-repeat';
                }

                if (pillarImage.length > 0) {
                    pillarImage.forEach((element) => {
                        element.style.backgroundImage = 'url(' + baseUrl + 'assets/images/element-bilder/decorelement-anthrazit.svg)';
                        element.style.backgroundRepeat = 'no-repeat';
                    });
                }

                if (pillarColor.length > 0) {
                    pillarColor.forEach((element) => {
                        element.style.backgroundColor = '#383e42';
                    });
                }
                if (element.classList.contains(ClassNames.LEFT_ABSCHLUSS)) {
                    const typeNumber = element.getAttribute('data-id');
                    if (typeNumber !== '0') {
                        let imgNode = element.querySelector('img');
                        imgNode.src = UrlHelper.getBaseUrl() + 'assets/images/abschluss/abschluss-' + typeNumber + 'o-anthrazit.png';
                    }
                } else if (element.classList.contains(ClassNames.RIGHT_ABSCHLUSS)) {
                    const typeNumber = element.getAttribute('data-id');
                    if (typeNumber !== '0') {
                        let imgNode = element.querySelector('img');
                        imgNode.src = UrlHelper.getBaseUrl() + 'assets/images/abschluss/abschluss-' + typeNumber + 'o-rotated-anthrazit.png';
                    }
                }


                DoorHandleHelper.setFrameColor('#383e42');
            } else if (color === 'stonegrey') {
                if (element.classList.contains(length_180)) {
                    if (element.classList.contains(Constants.SMALL_FENCE)) {

                        let nextElement = '';
                        if (allElements[index + 1] !== undefined) {
                            nextElement = allElements[index + 1];
                        }
                        if (nextElement) {
                            if (nextElement.classList.contains(Constants.HIGH_FENCE) || nextElement.classList.contains(DomIds.DECOR_ITEMS)) {
                                element.style.backgroundImage = 'url(' + baseUrl + 'assets/images/element-bilder/planerhintergrund-180-smallbig-stonegrey.svg)';
                            } else {
                                element.style.backgroundImage = 'url(' + baseUrl + 'assets/images/element-bilder/planerhintergrund-180-small-stonegrey.svg)';
                            }
                        } else {
                            element.style.backgroundImage = 'url(' + baseUrl + 'assets/images/element-bilder/planerhintergrund-180-small-stonegrey.svg)';
                        }

                    } else {
                        if (element.classList.contains(Constants.DOUBLE_DOOR_LEFT)) {
                            element.style.backgroundImage = ' ';
                            element.style.width = '353px';
                        } else {
                            element.style.backgroundImage = 'url(' + baseUrl + 'assets/images/element-bilder/planerhintergrund-180-stonegrey.svg)';
                        }

                    }
                    element.style.backgroundRepeat = 'no-repeat';

                }

                if (element.classList.contains(length_90)) {
                    if (element.classList.contains(Constants.SMALL_FENCE)) {
                        let nextElement = '';
                        if (allElements[index + 1] !== undefined) {
                            nextElement = allElements[index + 1];
                        }
                        if (nextElement) {
                            if (nextElement.classList.contains(Constants.HIGH_FENCE) || nextElement.classList.contains(DomIds.DECOR_ITEMS)) {
                                element.style.backgroundImage = 'url(' + baseUrl + 'assets/images/element-bilder/planerhintergrund-90-smallbig-stonegrey.svg)';
                            } else {
                                element.style.backgroundImage = 'url(' + baseUrl + 'assets/images/element-bilder/planerhintergrund-90-small-stonegrey.svg)';
                            }
                        } else {
                            element.style.backgroundImage = 'url(' + baseUrl + 'assets/images/element-bilder/planerhintergrund-90-small-stonegrey.svg)';
                        }

                    } else {
                        element.style.backgroundImage = 'url(' + baseUrl + 'assets/images/element-bilder/planerhintergrund-90-stonegrey.svg)';
                    }
                    element.style.backgroundRepeat = 'no-repeat';
                }

                if (pillarImage.length > 0) {
                    pillarImage.forEach((element) => {
                        let decorItem = DocumentSelector.selectAllByClass('decor-item');
                        let decorItemWidth = decorItem[0].innerWidth;
                        element.style.backgroundImage = 'url(' + baseUrl + 'assets/images/element-bilder/decorelement-grey.svg)';
                        element.style.backgroundSize = decorItemWidth;
                        element.style.backgroundRepeat = 'no-repeat';
                    });
                }

                if (pillarColor.length > 0) {
                    pillarColor.forEach((element) => {
                        element.style.backgroundColor = '#878581';
                    });
                }
                if (element.classList.contains(Constants.LEFT_ABSCHLUSS) && element.getAttribute('data-id') !== undefined) {
                    let typeNumber = element.getAttribute('data-id');
                    if (typeNumber !== '0') {
                        let imgNode = element.querySelector('img');
                        imgNode.src = UrlHelper.getBaseUrl() + 'assets/images/abschluss/abschluss-' + typeNumber + 'o.png';
                    }

                } else if (element.classList.contains(Constants.RIGHT_ABSCHLUSS)) {
                    const typeNumber = element.getAttribute('data-id');
                    if (typeNumber !== '0') {
                        let imgNode = element.querySelector('img');
                        imgNode.src = UrlHelper.getBaseUrl() + 'assets/images/abschluss/abschluss-' + typeNumber + 'o-rotated.png';
                    }
                }
                DoorHandleHelper.setFrameColor('#878581');
            }
        });
        this.setProfileColor();
    }

    setProfileColor() {
        let profileColorNode = document.querySelector('input[name="' + NodeNames.PROFILE_COLOR + '"]:checked');
        let selectedPillarColorNode = document.querySelector('input[name="postColor"]:checked');


        if (profileColorNode === null) {
            profileColorNode = documentSelector.selectById('profileColor');
        }


        let selectedPillarColor;
        if (selectedPillarColorNode !== null) {
            selectedPillarColor = selectedPillarColorNode.value;
        } else {
            selectedPillarColor = documentSelector.selectById('pillarsColor').textContent;
        }


        if (profileColorNode) {
            const elements = document.querySelectorAll('.table-element');
            for (const element of elements) {
                let newImagePath = '';
                const currentImagePath = element.style.backgroundImage;
                const pathParts = currentImagePath.replace('.svg")', '');
                let profileColor = profileColorNode.dataset.profilecolor;
                this.profileColor = profileColor;
                if (profileColor === 'eloxiert') {
                    profileColor = '';
                } else {
                    profileColor = '-' + profileColor;
                }

                newImagePath = pathParts + profileColor + '.svg")';
                element.style.backgroundImage = newImagePath;
            }


            const abschlussItems = document.querySelectorAll('.abschluss-item img');
            for (const abschlussItem of abschlussItems) {
                let newImagePath = '';
                const currentImagePath = abschlussItem.src;
                const pathParts = currentImagePath.replace('.png', '');
                let profileColor = profileColorNode.dataset.profilecolor;
                this.profileColor = profileColor;
                if (profileColor === 'eloxiert') {
                    profileColor = '';
                } else {
                    profileColor = '-' + profileColor;
                }

                if (selectedPillarColor === 'stonegrey' && profileColor === '-anthrazit') {
                    profileColor = '-stonegrey' + profileColor;
                }

                newImagePath = pathParts + profileColor + '.png';
                abschlussItem.src = newImagePath;
            }
        }
    }

    setPresentationFieldWidth() {
        let fieldWidth = DocumentSelector.selectById(DomIds.DRAWING_FIELD);
        let menuWidth = DocumentSelector.selectAllByClass('element-menu');

        let root = document.documentElement;

        if (fieldWidth !== null && menuWidth.length > 0) {
            let test1 = menuWidth[0].clientWidth;

            root.style.setProperty('--max-width', parseInt(test1 * 0.94) + 'px');
        }
    }

    saveDoorHandle(elementNode) {
        const element = this.elements[elementNode.dataset.number];
        if (element) {
            element.handlePosition = elementNode.dataset.handleposition;
        }
    }

    save() {
        this.savePillarsInputs();
        const data = {wall: this};
        const ajax = new Ajax('wall/save');
        ajax.post(data);
    }

}

// export class as singleton
export default (new Wall);
