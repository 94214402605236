import DocumentSelector from '../helper/DocumentSelector';
import {ClassNames} from '../constants/Constants';

class CustomerAddress {
    static getAddress() {
        const address = {};
        const coustomerNodes = DocumentSelector.selectAllByClass(ClassNames.ADDRESS_INPUTS);
        coustomerNodes.forEach((element) => {
            address[element.id] = element.value;
        });

        return address;
    }
}

export default CustomerAddress;