import {
    ClassNames as Constants,
    ClassNames, DataSets,
    DomIds,
    DoorOrType,
    HandlePosition,
    NodeNames
} from '../constants/Constants';
import DocumentSelector from './DocumentSelector';
import ImageHelper from './ImageHelper';
import Ajax from '../modules/Ajax';
import TypeHelper from './TypeHelper';
import ColorHelper from './ColorHelper';
import Wall from '../modules/Wall';
import ColorSelector from '../controller/ColorSelector';
import ElementLengthHelper from './ElementLengthHelper';
import CustomerAddress from '../modules/CustomerAddress';
import Router from '../modules/router/Router';
import {PageTitles, Routes} from '../modules/router/Routes';
import DoorHandleHelper from './DoorHandleHelper';

class EventHelper {
    static addTypeClickHandler(target) {
        target.addEventListener('click', EventHelper.clickType);
    }

    static addSmallTypeClickHandler(target) {
        target.addEventListener('click', EventHelper.clickSmallType);
    }

    static addDoorHandleClickHandler(target) {
        target.addEventListener('click', EventHelper.clickDoorHandlePosition);
    }

    static addDoorClickHandler(target) {
        target.addEventListener('click', EventHelper.clickDoor);
    }

    static addDoubeDoorClickHandler(target) {
        target.addEventListener('click', EventHelper.clickDoubleDoor);
    }

    static addDecorClickHandler(target) {
        target.addEventListener('click', EventHelper.clickDecor);
    }

    static addKeramikClickHandler(target) {
        target.addEventListener('click', EventHelper.clickKeramik);
    }

    static addAbschlussClickHandler(target) {
        target.addEventListener('click', EventHelper.clickAbschluss);
    }

    static addSmallFenceClickHandler(target) {
        target.addEventListener('click', EventHelper.clickSmallFenceElement);
    }

    static addStandardElementClickHandler(target) {
        target.addEventListener('click', EventHelper.clickStandardElement);
    }

    static clickStandardElement(e) {
        ImageHelper.deselectAllStandardElementImages();

        const typeNumber = e.target.dataset.standard;
        const imgNode = DocumentSelector.selectById('img-standard-' + typeNumber);
        if (imgNode) {
            imgNode.src = ImageHelper.getSelectedStandardElementImagePath(typeNumber);
        }

        TypeHelper.setSelectedStandardElementNumber(typeNumber);

        const selectedType = TypeHelper.getSelectedStandardElement();
        Wall.addElementFromSelectedType(selectedType, typeNumber, false, false);
        Wall.savePillarsInputs();

        const data = {'wall': Wall, 'showElement': true, 'showColor': true, 'buttonsActive': true};
        const ajax = new Ajax('ajax-update.php?viewFile=wallColor');
        ajax.post(data, () => {
            Router.pushState(Routes.COLOR_SELECTION);
            Router.changeTitle(PageTitles.COLOR_SELECTION);
            ElementLengthHelper.deactivateAllButOneActiveElement();
        });

        const previewImage = document.getElementById('previewImage');
        previewImage.classList.add('hidden');
    }

    static clickSmallFenceElement(e) {
        ImageHelper.deselectAllSmallFenceElementImages();

        const typeNumber = e.target.dataset.standard;
        const imgNode = DocumentSelector.selectById('img-smallfence-' + typeNumber);
        if (imgNode) {
            imgNode.src = ImageHelper.getSelectedSmallFenceElementImagePath(typeNumber);
        }

        TypeHelper.setSelectedSmallFenceElementNumber(typeNumber);

        const selectedType = TypeHelper.getSelectedSmallFenceElement();
        Wall.addElementFromSelectedType(selectedType, typeNumber, false, true);
        Wall.savePillarsInputs();
        const data = {'wall': Wall, 'showElement': true, 'showColor': true, 'buttonsActive': true};
        const ajax = new Ajax('ajax-update.php?viewFile=wallColor');
        ajax.post(data, () => {
            Router.pushState(Routes.COLOR_SELECTION);
            Router.changeTitle(PageTitles.COLOR_SELECTION);
            ElementLengthHelper.deactivateAllButOneActiveElement();
        });

        const previewImage = document.getElementById('previewImage');
        previewImage.classList.add('hidden');
    }

    static clickType(e) {
        ImageHelper.deselectAllTypeImages(DoorOrType.TYPE, ClassNames.TYPE);

        const typeNumber = e.target.dataset.type;
        const imgNode = DocumentSelector.selectById('img-type-' + typeNumber);
        imgNode.src = ImageHelper.getSelectedTypeImagePath(typeNumber, DoorOrType.TYPE);

        TypeHelper.setIsDoor(false);
        TypeHelper.setIsDoubleDoor(false);
        TypeHelper.setIsSmallType(false);
        TypeHelper.setSelectedTypeNumber(typeNumber);

        EventHelper.showElementInChoiceField(imgNode.src);

        const previewImage = document.getElementById('previewImage');
        previewImage.classList.add('hidden');
        Router.pushState(Routes.ELEMENT_SELECTION);
        Router.changeTitle(PageTitles.ELEMENT_SELECTION);
    }

    static clickSmallType(e) {
        ImageHelper.deselectAllSmallTypeImages(ClassNames.SMALL_TYPE);

        const typeNumber = e.target.dataset.type;
        const imgNode = DocumentSelector.selectById('smallimg-type-' + typeNumber);
        imgNode.src = ImageHelper.getSelectedSmallTypeImagePath(typeNumber, ClassNames.SMALL_TYPE);

        TypeHelper.setIsDoor(false);
        TypeHelper.setIsSmallType(true);
        TypeHelper.setSmallSelectedTypeNumber(typeNumber);

        EventHelper.showElementInChoiceField(imgNode.src);

        const previewImage = document.getElementById('previewImage');
        previewImage.classList.add('hidden');
        Router.pushState(Routes.ELEMENT_SELECTION);
        Router.changeTitle(PageTitles.ELEMENT_SELECTION);
    }

    static clickDoorHandlePosition(e) {
        const activeCassette = DocumentSelector.selectFirstByClass(ClassNames.WALL_ELEMENT_ACTIVE);
        let element;
        if (activeCassette) {
            element = activeCassette.parentNode;
        } else {
            element = DocumentSelector.selectFirstByClass(ClassNames.TABLE_ACTIVE);
        }
        let dataNumber = element.dataset.number;
        if (element.classList.contains(Constants.DOUBLE_DOOR_LEFT)) {
            dataNumber = parseInt(dataNumber);
            dataNumber += 1;
        } else {
            dataNumber = parseInt(dataNumber);
            dataNumber -= 1;
        }
        let nextDoorElement = document.querySelectorAll('[data-number="' + dataNumber + '"]');
        if (element.classList.contains(Constants.DOUBLE_DOOR_LEFT) || element.classList.contains(Constants.DOUBLE_DOOR_RIGHT)) {
            // const doubleDoorRightHandle = element.querySelector('.' + ClassNames.DRAWING_DOUBLE_DOOR_HANDLE_RIGHT);
            //const doubleDoorLeftHandle = element.querySelector('.' + ClassNames.DRAWING_DOOR_HANDLE_LEFT);
            let doubleDoorRightHandle = '';
            let doubleDoorLeftHandle = '';
            let selectedOption = e.target.id;
            if (element.classList.contains(Constants.DOUBLE_DOOR_LEFT) && e.target.id === DomIds.DOOR_HANDLE_LEFT_RADIO) {
                doubleDoorRightHandle = nextDoorElement[0].querySelector('.' + ClassNames.DOUBLE_DOOR_RIGHT_HANDLE);
                doubleDoorLeftHandle = element.querySelector('.' + ClassNames.DOUBLE_DOOR_LEFT_HANDLE);
                doubleDoorRightHandle.classList.add(ClassNames.HIDDEN);
                doubleDoorLeftHandle.classList.remove(ClassNames.HIDDEN);
                element.dataset.handleposition = HandlePosition.LEFT;
                nextDoorElement[0].dataset.handleposition = 'hidden';

            } else if (element.classList.contains(Constants.DOUBLE_DOOR_LEFT) && e.target.id === DomIds.DOOR_HANDLE_RIGHT_RADIO) {
                doubleDoorRightHandle = nextDoorElement[0].querySelector('.' + ClassNames.DOUBLE_DOOR_RIGHT_HANDLE);
                doubleDoorLeftHandle = element.querySelector('.' + ClassNames.DOUBLE_DOOR_LEFT_HANDLE);
                doubleDoorRightHandle.classList.remove(ClassNames.HIDDEN);
                doubleDoorLeftHandle.classList.add(ClassNames.HIDDEN);
                element.dataset.handleposition = HandlePosition.RIGHT;
                nextDoorElement[0].dataset.handleposition = 'hidden';
            } else if (element.classList.contains(Constants.DOUBLE_DOOR_RIGHT) && e.target.id === DomIds.DOOR_HANDLE_LEFT_RADIO) {
                doubleDoorRightHandle = element.querySelector('.' + ClassNames.DOUBLE_DOOR_RIGHT_HANDLE);
                doubleDoorLeftHandle = nextDoorElement[0].querySelector('.' + ClassNames.DOUBLE_DOOR_LEFT_HANDLE);

                doubleDoorLeftHandle.classList.remove(ClassNames.HIDDEN);
                doubleDoorRightHandle.classList.add(ClassNames.HIDDEN);
                element.dataset.handleposition = 'hidden';
                nextDoorElement[0].dataset.handleposition = HandlePosition.LEFT;
            } else if (element.classList.contains(Constants.DOUBLE_DOOR_RIGHT) && e.target.id === DomIds.DOOR_HANDLE_RIGHT_RADIO) {
                doubleDoorRightHandle = element.querySelector('.' + ClassNames.DOUBLE_DOOR_RIGHT_HANDLE);
                doubleDoorLeftHandle = nextDoorElement[0].querySelector('.' + ClassNames.DOUBLE_DOOR_LEFT_HANDLE);

                doubleDoorLeftHandle.classList.add(ClassNames.HIDDEN);
                doubleDoorRightHandle.classList.remove(ClassNames.HIDDEN);
                element.dataset.handleposition = HandlePosition.RIGHT;
                nextDoorElement[0].dataset.handleposition = ClassNames.HIDDEN;
            }

        } else {
            const leftHandle = element.querySelector('.' + ClassNames.DRAWING_DOOR_HANDLE_LEFT);
            const rightHandle = element.querySelector('.' + ClassNames.DRAWING_DOOR_HANDLE_RIGHT);
            const lefHandleOpenDoor = element.querySelector('.' + ClassNames.OPEN_DOOR_HANDLE_LEFT);
            const rightHandleOpenDoor = element.querySelector('.' + ClassNames.OPEN_DOOR_HANDLE_RIGHT);
            if (leftHandle && e.target.id === DomIds.DOOR_HANDLE_RIGHT_RADIO) {
                leftHandle.classList.add(ClassNames.HIDDEN);
                rightHandle.classList.remove(ClassNames.HIDDEN);
                lefHandleOpenDoor.classList.add(ClassNames.HIDDEN);
                rightHandleOpenDoor.classList.remove(ClassNames.HIDDEN);
                element.dataset.handleposition = HandlePosition.RIGHT;
            } else if (rightHandle) {
                leftHandle.classList.remove(ClassNames.HIDDEN);
                rightHandle.classList.add(ClassNames.HIDDEN);
                lefHandleOpenDoor.classList.remove(ClassNames.HIDDEN);
                rightHandleOpenDoor.classList.add(ClassNames.HIDDEN);
                element.dataset.handleposition = HandlePosition.LEFT;
            }
        }
        if (Wall) {
            Wall.saveDoorHandle(element);
            Wall.save();
        }
    }

    static clickDoor(e) {
        ImageHelper.deselectAllTypeImages(DoorOrType.DOOR, ClassNames.DOOR);

        const typeNumber = e.target.dataset.type;
        const imgNode = DocumentSelector.selectById('img-door-' + typeNumber);
        imgNode.src = ImageHelper.getSelectedTypeImagePath(typeNumber, DoorOrType.DOOR);

        TypeHelper.setIsDoor(true);
        TypeHelper.setIsDoubleDoor(false);
        TypeHelper.setIsSmallType(false);
        TypeHelper.setSelectedTypeNumber(typeNumber);

        EventHelper.showElementInChoiceField(imgNode.src);

        const previewImage = document.getElementById('previewImage');
        previewImage.classList.add('hidden');
        Router.pushState(Routes.ELEMENT_SELECTION);
        Router.changeTitle(PageTitles.ELEMENT_SELECTION);
    }

    static clickDoubleDoor(e) {
        ImageHelper.deselectAllTypeImages(DoorOrType.DOUBLE_DOOR, ClassNames.DOUBLE_DOOR);

        const typeNumber = e.target.dataset.type;
        const imgNode = DocumentSelector.selectById('img-double-door-' + typeNumber);
        imgNode.src = ImageHelper.getSelectedTypeImagePath(typeNumber, DoorOrType.DOUBLE_DOOR);

        TypeHelper.setIsDoubleDoor(true);
        TypeHelper.setIsDoor(false);
        TypeHelper.setIsSmallType(false);
        TypeHelper.setSelectedTypeNumber(typeNumber);

        EventHelper.showElementInChoiceField(imgNode.src, true);

        const previewImage = document.getElementById('previewImage');
        previewImage.classList.add('hidden');
        Router.pushState(Routes.ELEMENT_SELECTION);
        Router.changeTitle(PageTitles.ELEMENT_SELECTION);
    }

    static defaultElement() {
        ImageHelper.deselectAllTypeImages(DoorOrType.TYPE, ClassNames.TYPE);

        const typeNumber = '2';
        const imgNode = DocumentSelector.selectById('img-type-' + typeNumber);

        if (imgNode) {
            imgNode.src = ImageHelper.getSelectedTypeImagePath(typeNumber, DoorOrType.TYPE);
            TypeHelper.setIsDoor(false);
            TypeHelper.setSelectedTypeNumber(typeNumber);
            EventHelper.showElementInChoiceField(imgNode.src);
        }
    }

    static clickDecor(e) {
        ImageHelper.deselectAllDecorImages();

        const typeNumber = e.target.dataset.decor;
        const imgNode = DocumentSelector.selectById('img-decor-' + typeNumber);
        imgNode.src = ImageHelper.getSelectedDecorImagePath(typeNumber);
        TypeHelper.setSelectedDecorNumber(imgNode.src);

        const selectedType = TypeHelper.getSelectedDecorNumber();
        Wall.addDecorFromSelectedType(selectedType);
        Wall.savePillarsInputs();
        const data = {'wall': Wall, 'showElement': true, 'buttonsActive': true, 'showColor': true};
        const ajax = new Ajax('ajax-update.php?viewFile=wallColor');
        ajax.post(data, () => {
            Router.pushState(Routes.COLOR_SELECTION);
            Router.changeTitle(PageTitles.COLOR_SELECTION);
            ElementLengthHelper.deactivateAllButOneActiveElement();
        });
    }

    static clickKeramik(e) {
        ImageHelper.deselectAllKeramikImages();
        let length90 = e.target.classList.contains('length-90');
        const typeNumber = e.target.dataset.keramik;
        let length = '';
        if (length90) {
            length = 'length-90';
        } else {
            length = 'length-180';
        }

        const src = ImageHelper.getSelectedKeramikImagePath(typeNumber, length);
        TypeHelper.setSelectedKeramikNumber(src);
        const selectedType = TypeHelper.getSelectedKeramikNumber();
        Wall.addKeramikFromSelectedType(selectedType, length);
        Wall.savePillarsInputs();
        const data = {'wall': Wall, 'showElement': true, 'buttonsActive': true, 'showColor': true};
        const ajax = new Ajax('ajax-update.php?viewFile=wallColor');
        ajax.post(data, () => {
            Router.pushState(Routes.COLOR_SELECTION);
            Router.changeTitle(PageTitles.COLOR_SELECTION);
            ElementLengthHelper.deactivateAllButOneActiveElement();
        });
    }

    static clickAbschluss(e) {
        ImageHelper.deselectAllAbschlussImages();
        let allElements = DocumentSelector.selectAllByClass('element-article');
        const index = allElements.length - 1;
        let rotation = false;
        if (allElements[index]) {
            if (
                allElements[index].classList.contains(Constants.HIGH_FENCE)
                || (allElements[index].classList.contains(Constants.LEFT_ABSCHLUSS))
                || (allElements[index].classList.contains(DomIds.DECOR_ITEMS))
            ) {
                rotation = true;
            }
        }
        const typeNumber = e.target.dataset.abschluss;
        const imgNode = DocumentSelector.selectById('img-abschluss-' + typeNumber);
        imgNode.setAttribute('data-abschluss', typeNumber);
        imgNode.src = ImageHelper.getSelectedAbschlussImagePath(typeNumber, rotation);
        TypeHelper.setSelectedAbschlussNumber(imgNode.src);
        const selectedType = TypeHelper.getSelectedAbschlussNumber();
        Wall.addAbschlussFromSelectedType(selectedType);
        Wall.savePillarsInputs();
        const data = {
            'wall': Wall,
            'showElement': true,
            'buttonsActive': true,
            'showColor': true,
            'type_number': parseInt(typeNumber)
        };
        const ajax = new Ajax('ajax-update.php?viewFile=wallColor');
        ajax.post(data, () => {
            Router.pushState(Routes.COLOR_SELECTION);
            Router.changeTitle(PageTitles.COLOR_SELECTION);
            ElementLengthHelper.deactivateAllButOneActiveElement();
        });
    }

    static showElementInChoiceField(imageUrl, doubleDoor = null) {
        const imageField = document.getElementById('elementimagePlace');
        imageField.innerHTML = '';

        const decorField = document.getElementById('decorimagePlace');
        decorField.innerHTML = '';

        const imgNodeForChoiceField = document.createElement('img');
        imgNodeForChoiceField.setAttribute('src', imageUrl);
        if (doubleDoor) {
            imgNodeForChoiceField.setAttribute('class', 'templateImage image-length-180 double-image-length-180');
        } else {
            imgNodeForChoiceField.setAttribute('class', 'templateImage image-length-180');
        }

        imageField.appendChild(imgNodeForChoiceField);

        if (imgNodeForChoiceField.getBoundingClientRect().right > 1670) {
            const scrollField = document.getElementById(DomIds.DRAWING_FIELD);
            // scrollField.scrollLeft += 370;
            let distance = imgNodeForChoiceField.getBoundingClientRect().right - 1670;
            scrollField.scrollLeft += distance;
        }

        if (Wall.elements.length > 0) {
            const relevantTable = document.querySelectorAll('[data-number="' + Wall.relevantTable + '"]');
            Wall.relevantTablePosition = relevantTable[0].getBoundingClientRect().right;
        } else {
            Wall.relevantTablePosition = imgNodeForChoiceField.getBoundingClientRect().right;
        }

        const confirmButton = document.getElementById(DomIds.CONFIRMBUTTON);
        confirmButton.classList.remove('hidden');
        confirmButton.addEventListener('click', EventHelper.clickTypeConfirm);

        const breakButton = document.getElementById('breakbutton');

        if (Wall.elements.length > 0) {
            breakButton.classList.remove('hidden');
        }

        breakButton.addEventListener('click', EventHelper.clickBreak);

        if (!EventHelper.areElementPreviewImagesInactive()) {
            EventHelper.changeCursorOfWallElementsToDefault();
        }
    }

    static addConfirmEvent(target) {
        target.addEventListener('click', EventHelper.clickStart);
    }

    static addStartEvent() {
        const startButtonEndkunde = DocumentSelector.selectById(DomIds.START_PLANNING_BUTTON);
        if (startButtonEndkunde) {
            startButtonEndkunde.addEventListener('click', EventHelper.clickStartEndkundenVersion);
        }
    }

    static clickStartEndkundenVersion() {
        const selectedType = TypeHelper.getSelectedType();
        selectedType.elementSrc = EventHelper.showSrcOfDefaultElement(TypeHelper.getSelectedTypeNumber());
        selectedType.showTypeSelection = true;
        const data = {selectedType: selectedType};
        const ajax = new Ajax('ajax-begin.php?viewFile=wallColor&start=newEndkunde');
        ajax.post(data, () => {
            Router.pushState(Routes.ELEMENT_SELECTION);
            Router.changeTitle(PageTitles.ELEMENT_SELECTION);
        });
    }

    static addLoadEvent() {
        const loadPlanningEndCustomer = DocumentSelector.selectById(DomIds.LOAD_PLANNING_BUTTON_END_CUSTOMER);
        if (loadPlanningEndCustomer) {
            loadPlanningEndCustomer.addEventListener('click', () => {
                const numberInput = DocumentSelector.selectById(DomIds.USER_LOAD_NUMBER);

                const data = {number: numberInput.value};
                const ajax = new Ajax('ajax-begin.php?viewFile=wallColor&start=loadEndkunde');
                ajax.post(data, () => {
                    Router.pushState(Routes.COLOR_SELECTION);
                    Router.changeTitle(PageTitles.COLOR_SELECTION);
                }, true);
            });
        }


        const loadPlanningStockist = DocumentSelector.selectById(DomIds.LOAD_PLANNING_BUTTON_STOCKIST);
        if (loadPlanningStockist) {
            loadPlanningStockist.addEventListener('click', () => {
                const numberInput = DocumentSelector.selectById(DomIds.USER_LOAD_NUMBER);
                const emailInput = DocumentSelector.selectById(DomIds.USER_LOAD_EMAIL);
                const passwordInput = DocumentSelector.selectById(DomIds.USER_LOAD_PASSWORD);

                const data = {number: numberInput.value, email: emailInput.value, password: passwordInput.value};
                const ajax = new Ajax('ajax-begin.php?viewFile=wallColor&start=loadStockist');
                ajax.post(data, () => {
                    Router.pushState(Routes.COLOR_SELECTION);
                    Router.changeTitle(PageTitles.COLOR_SELECTION);
                }, true);
            });
        }
    }

    static addShowLoadEvent() {
        const showLoadPlanningEndCustomer = DocumentSelector.selectById(DomIds.SHOW_LOAD_PLANNING_BUTTON_END_CUSTOMER);
        if (showLoadPlanningEndCustomer) {
            showLoadPlanningEndCustomer.addEventListener('click', () => {
                const startEndCustomer = DocumentSelector.selectById(DomIds.START_END_CUSTOMER);
                startEndCustomer.classList.add(ClassNames.HIDE_FLEX);
                startEndCustomer.classList.remove(ClassNames.SHOW_FLEX);

                const loadEndCustomerForm = DocumentSelector.selectById(DomIds.LOAD_END_CUSTOMER_PLANNING);
                loadEndCustomerForm.classList.add(ClassNames.SHOW_FLEX);
                loadEndCustomerForm.classList.remove(ClassNames.HIDE_FLEX);
            });
        }

        const showLoadPlanningStockst = DocumentSelector.selectById(DomIds.SHOW_LOAD_PLANNING_BUTTON_STOCKIST);
        if (showLoadPlanningStockst) {
            showLoadPlanningStockst.addEventListener('click', () => {
                const startStockist = DocumentSelector.selectById(DomIds.START_STOCKIST);
                startStockist.classList.add(ClassNames.HIDE_FLEX);
                startStockist.classList.remove(ClassNames.SHOW_FLEX);

                const loadStockistForm = DocumentSelector.selectById(DomIds.LOAD_STOCKIST_PLANNING);
                loadStockistForm.classList.add(ClassNames.SHOW_FLEX);
                loadStockistForm.classList.remove(ClassNames.HIDE_FLEX);

                showLoadPlanningStockst.style.display = 'none';

                const backLoadPlanningStockst = DocumentSelector.selectById(DomIds.HIDE_LOAD_PLANNING_BUTTON_STOCKIST);
                backLoadPlanningStockst.style.display = 'block';
            });
        }
    }

    static addBackLoadEvent() {
        const backLoadPlanningEndCustomer = DocumentSelector.selectById(DomIds.LOAD_PLANNING_BACK_BUTTON);
        if (backLoadPlanningEndCustomer) {
            backLoadPlanningEndCustomer.addEventListener('click', () => {
                const startEndCustomer = DocumentSelector.selectById(DomIds.START_END_CUSTOMER);
                startEndCustomer.classList.add(ClassNames.SHOW_FLEX);
                startEndCustomer.classList.remove(ClassNames.HIDE_FLEX);

                const loadEndCustomerForm = DocumentSelector.selectById(DomIds.LOAD_END_CUSTOMER_PLANNING);
                loadEndCustomerForm.classList.add(ClassNames.HIDE_FLEX);
                loadEndCustomerForm.classList.remove(ClassNames.SHOW_FLEX);
            });
        }


        const backLoadPlanningStockst = DocumentSelector.selectById(DomIds.HIDE_LOAD_PLANNING_BUTTON_STOCKIST);
        if (backLoadPlanningStockst) {
            backLoadPlanningStockst.addEventListener('click', () => {
                const startStockist = DocumentSelector.selectById(DomIds.START_STOCKIST);
                startStockist.classList.add(ClassNames.SHOW_FLEX);
                startStockist.classList.remove(ClassNames.HIDE_FLEX);

                const loadStockistForm = DocumentSelector.selectById(DomIds.LOAD_STOCKIST_PLANNING);
                loadStockistForm.classList.add(ClassNames.HIDE_FLEX);
                loadStockistForm.classList.remove(ClassNames.SHOW_FLEX);

                const showLoadPlanningStockst = DocumentSelector.selectById(DomIds.SHOW_LOAD_PLANNING_BUTTON_STOCKIST);
                showLoadPlanningStockst.style.display = 'block';

                backLoadPlanningStockst.style.display = 'none';
            });
        }
    }

    static clickStart(e) {
        if (e.target.classList.contains(ClassNames.CONFIRM)) {
            const selectedType = TypeHelper.getSelectedType();
            selectedType.elementSrc = EventHelper.showSrcOfDefaultElement(TypeHelper.getSelectedTypeNumber());
            selectedType.showTypeSelection = true;

            const userMailNode = DocumentSelector.selectById(DomIds.EMAIL);
            const userPasswordNode = DocumentSelector.selectById(DomIds.PASSWORD);

            const data = {selectedType: selectedType, email: userMailNode.value, password: userPasswordNode.value};

            const ajax = new Ajax('ajax-begin.php?viewFile=wallColor');
            ajax.post(data);
        }
    }

    static clickConfirm(e) {
        if (e.target.classList.contains(ClassNames.CONFIRM)) {
            const selectedType = TypeHelper.getSelectedType();
            selectedType.elementSrc = EventHelper.showSrcOfDefaultElement(TypeHelper.getSelectedTypeNumber());
            selectedType.showTypeSelection = true;
            const ajax = new Ajax('ajax-update.php?viewFile=wallColor');
            ajax.post(selectedType);
        }
    }

    static showSrcOfDefaultElement(typeNumber) {
        const src = ImageHelper.getSelectedTypeImagePath(typeNumber, DoorOrType.TYPE);
        return src;
    }

    static addElementLengthClickHandler(target) {
        target.addEventListener('click', EventHelper.clickElementLength);
    }

    static clickElementLength(e) {
        let activeElement = document.getElementsByClassName(ClassNames.ACTIVE);
        if (activeElement[0].classList.contains(ClassNames.KERAMIK_ITEM)) {
            Wall.changeKeramikLength(e);
        } else {
            Wall.changeCassetteLength(e);
            ElementLengthHelper.setCassettesWidth();
            Wall.savePillarsInputs();
            Wall.save();
        }
    }

    static addColorClickHandler(target) {
        target.addEventListener('click', EventHelper.clickColor);
    }

    static changeColorClickHandler(target) {
        target.addEventListener('click', EventHelper.clickCassette);
    }

    static selectKeramik(target) {
        target.addEventListener('click', EventHelper.clickKeramikElement);
    }

    static clickColor(e) {
        const colorHelper = new ColorHelper();
        colorHelper.addColorClass(e.target.id);
        colorHelper.deleteAllActiveColorClasses();
        colorHelper.setActiveColor(e.target);

        DoorHandleHelper.select(e.target);

        let activeWallelementId = colorHelper.clickedWall.getAttribute('id');
        let tableNumber = 'table-' + EventHelper.getRelatedTableNumberById(activeWallelementId);
        if (colorHelper.getCountOfCassetteElements(tableNumber) === colorHelper.getCountOfColoredCassettes(tableNumber)) {
            colorHelper.activateColorConfirm(tableNumber);

            EventHelper.activatePlusMinusButtons();
        }

        const element = colorHelper.clickedWall.parentElement;
        const elementClassList = element.classList;

        elementClassList.forEach((elementClass) => {
            if (elementClass.indexOf('standard-element-') !== -1) {
                element.classList.remove(elementClass);
            }
            if (elementClass.indexOf('smallfence-standard-') !== -1) {
                element.classList.remove(elementClass);
            }
        });

        Wall.addElementFromColorSelection();
        Wall.setPillarColor();
        Wall.save();
    }

    static clickCassette(e) {
        if (EventHelper.areElementPreviewImagesInactive()) {
            Wall.enableActiveClass(e.target.parentElement);
            EventHelper.createColorSelector();
            const colorHelper = new ColorHelper();
            DoorHandleHelper.select(e.target.parentElement);
            colorHelper.setActiveFlag(e.target);
            colorHelper.hideShowDecorColors();

            ElementLengthHelper.checkLengthOfActiveCassette(e);
            ElementLengthHelper.hideOrShow(e.target);
            DoorHandleHelper.hideOrShow(e);
            ColorHelper.activateColorTab();
        }
    }

    static clickKeramikElement(e) {
        Wall.enableActiveClass(e.currentTarget);
        let allWallElements = document.getElementsByClassName(ClassNames.WALL_ELEMENT_ACTIVE);
        allWallElements.forEach((element) => {
            if (element.classList.contains(ClassNames.WALL_ELEMENT_ACTIVE)) {
                element.classList.remove(ClassNames.WALL_ELEMENT_ACTIVE);
            }
        });
        e.currentTarget.querySelector('img').classList.add(ClassNames.WALL_ELEMENT_ACTIVE);
        let lengthButton = '';
        if (e.currentTarget.classList.contains('length-180')) {
            lengthButton = DocumentSelector.selectById(DomIds.LENGTH_180);
            lengthButton.checked = true;
        } else if (e.currentTarget.classList.contains('length-90')) {
            lengthButton = DocumentSelector.selectById(DomIds.LENGTH_90);
            lengthButton.checked = true;
        }
        ElementLengthHelper.hideOrShow(e.target);
    }

    static areElementPreviewImagesInactive() {
        const previewImage = document.getElementById('previewImage');
        let previewImageHidden;
        if (previewImage !== null) {
            previewImageHidden = previewImage.classList.contains('hidden');
        } else {
            previewImageHidden = true;
        }
        const elementImagePlace = document.getElementById('elementimagePlace');
        let elementImagePlaceCount;
        if (elementImagePlace !== null) {
            elementImagePlaceCount = elementImagePlace.childElementCount;
        } else {
            elementImagePlaceCount = 0;
        }

        const decorImagePlace = document.getElementById('decorimagePlace');
        let decorImagePlaceCount;
        if (decorImagePlace !== null) {
            decorImagePlaceCount = decorImagePlace.childElementCount;
        } else {
            decorImagePlaceCount = 0;
        }

        return elementImagePlaceCount === 0 && decorImagePlaceCount === 0 && previewImageHidden;
    }

    static changeCursorOfWallElementsToDefault() {
        const wallElements = document.getElementsByClassName(Constants.WALL_ELEMENT);
        for (const wallElement of wallElements) {
            wallElement.style.cursor = 'default';
        }
    }

    static clickTypeConfirm() {
        let selectedType = TypeHelper.getSelectedType();
        if (TypeHelper.isDoor) {
            selectedType.elementLength = 90;
        }
        if (TypeHelper.isSmallType) {
            selectedType = TypeHelper.getSmallSelectedType();
            Wall.addElementFromSelectedType(selectedType, '', TypeHelper.isDoor, true, true);
        } else {
            if (TypeHelper.isDoubleDoor) {
                selectedType.elementLength = 180;
                for (let i = 0; i < 2; i++) {
                    Wall.addElementFromSelectedType(selectedType, '', TypeHelper.isDoor, false, false, TypeHelper.isDoubleDoor, i);
                }
            } else {
                Wall.addElementFromSelectedType(selectedType, '', TypeHelper.isDoor, false, false, TypeHelper.isDoubleDoor);
            }

        }

        Wall.savePillarsInputs();

        const data = {'wall': Wall, 'showElement': true, 'showColor': true, 'minusButtonActive': true};
        const ajax = new Ajax('ajax-update.php?viewFile=wallColor');
        ajax.post(data, () => {

            ElementLengthHelper.deactivateAllButOneActiveElement();

            Router.pushState(Routes.COLOR_SELECTION);
            Router.changeTitle(PageTitles.COLOR_SELECTION);

            ElementLengthHelper.deactivateAllButOneActiveElement();
            EventHelper.selectDoorHandleAndElementWidth();
        });
    }

    static clickBreak() {
        const data = {'wall': Wall, 'showElement': true, 'buttonsActive': true, 'showColor': true};
        const ajax = new Ajax('ajax-update.php?viewFile=wallColor');
        ajax.post(data, () => {
            Router.pushState(Routes.COLOR_SELECTION);
            Router.changeTitle(PageTitles.COLOR_SELECTION);
        });
    }

    static addConfirmDecorClickHandler(e) {
        e.addEventListener('click', EventHelper.clickDecorChoiceConfirm);
    }

    static clickDecorChoiceConfirm(e) {
        let decorConfirmButtonId = e.target.getAttribute('id');
        let decorPlusButtonId = decorConfirmButtonId.replace('color', 'plus');
        const decorPlusButton = DocumentSelector.selectById(decorPlusButtonId);
        decorPlusButton.classList.remove('plus_of');
        decorPlusButton.classList.add('plus');
        decorPlusButton.addEventListener('click', EventHelper.clickPlusDecorConfirm);
    }

    static clickPlusDecorConfirm(e) {
        let activeElementId = e.target.getAttribute('id');
        let relatedTableNumber = EventHelper.getRelatedTableNumberById(activeElementId);
        Wall.relevantTable = relatedTableNumber;

        let tableElement = document.querySelectorAll('[data-number="' + relatedTableNumber + '"]');
        Wall.relevantTablePosition = tableElement[0].getBoundingClientRect().right;
        const data = {
            'wall': Wall, 'showElement': true, 'showTypeSelection': true,
            'showPreviewImage': true, 'breakbuttonVisibility': ''
        };

        const ajax = new Ajax('ajax-update.php?viewFile=wallColor');
        ajax.post(data, () => {
            Router.pushState(Routes.ELEMENT_SELECTION);
            Router.changeTitle(PageTitles.ELEMENT_SELECTION);
        });
    }

    static clickPlusConfirm(e) {
        if (e.target.classList.contains('plus')) {
            const clickedPlusButton = e.target.getAttribute('id');
            const relatedTableNumber = EventHelper.getRelatedTableNumberById(clickedPlusButton);
            Wall.relevantTable = relatedTableNumber;

            let tableElement = document.querySelectorAll('[data-number="' + relatedTableNumber + '"]');
            Wall.relevantTablePosition = tableElement[0].getBoundingClientRect().right;

            Wall.addElementFromColorSelection();
            const data = {
                'wall': Wall, 'showElement': true, 'showTypeSelection': true,
                'showPreviewImage': true, 'breakbuttonVisibility': ''
            };

            const ajax = new Ajax('ajax-update.php?viewFile=wallColor');
            ajax.post(data, () => {
                Router.pushState(Routes.ELEMENT_SELECTION);
                Router.changeTitle(PageTitles.ELEMENT_SELECTION);
            });
        }
    }

    static getRelatedTableNumberById(elementId) {
        const splittedId = elementId.split('-');
        const countSplittedId = splittedId.length;
        return parseInt(splittedId[countSplittedId - 1]);

    }

    static clickMinusConfirm(e) {
        if (e.target.classList.contains('minus')) {
            const clickedMinusButton = e.target.getAttribute('id');
            const toDeleteTableNumber = EventHelper.getRelatedTableNumberById(clickedMinusButton);
            Wall.deleteElementWithId(toDeleteTableNumber);
            const data = {'wall': Wall, 'showElement': true, 'buttonsActive': true, 'showColor': true};
            const ajax = new Ajax('ajax-update.php?viewFile=wallColor');
            ajax.post(data, () => {
                Router.pushState(Routes.COLOR_SELECTION);
                Router.changeTitle(PageTitles.COLOR_SELECTION);
                ElementLengthHelper.deactivateAllButOneActiveElement();
            });
        }
    }

    static clickCopyConfirm(e) {
        if (e.target.classList.contains('copy')) {
            const clickedCopyButton = e.target.getAttribute('id');
            const toCopyTableNumber = EventHelper.getRelatedTableNumberById(clickedCopyButton);
            Wall.relevantTable = toCopyTableNumber;

            let tableElement = document.querySelectorAll('[data-number="' + toCopyTableNumber + '"]');
            Wall.relevantTablePosition = tableElement[0].getBoundingClientRect().right;
            Wall.copyCurrentElement();
            const data = {'wall': Wall, 'showElement': true, 'buttonsActive': true, 'showColor': true};

            const ajax = new Ajax('ajax-update.php?viewFile=wallColor');
            ajax.post(data, () => {
                Router.pushState(Routes.COLOR_SELECTION);
                Router.changeTitle(PageTitles.COLOR_SELECTION);
                ElementLengthHelper.deactivateAllButOneActiveElement();
            });
        }
    }

    static clickCopyKeramikConfirm(e) {
        if (e.target.classList.contains('copy')) {
            const clickedCopyButton = e.target.getAttribute('id');
            const toCopyTableNumber = EventHelper.getRelatedTableNumberById(clickedCopyButton);
            Wall.relevantTable = toCopyTableNumber;

            let tableElement = document.querySelectorAll('[data-number="' + toCopyTableNumber + '"]');
            Wall.relevantTablePosition = tableElement[0].getBoundingClientRect().right;
            Wall.copyCurrentKeramikElement();
            const data = {'wall': Wall, 'showElement': true, 'buttonsActive': true, 'showColor': true};

            const ajax = new Ajax('ajax-update.php?viewFile=wallColor');
            ajax.post(data, () => {
                Router.pushState(Routes.COLOR_SELECTION);
                Router.changeTitle(PageTitles.COLOR_SELECTION);
                ElementLengthHelper.deactivateAllButOneActiveElement();
            });
        }
    }

    static clickDecorCopyConfirm(e) {
        if (e.target.classList.contains('copy')) {
            const clickedCopyButton = e.target.getAttribute('id');
            const toCopyTableNumber = EventHelper.getRelatedTableNumberById(clickedCopyButton);
            Wall.relevantTable = toCopyTableNumber;

            let tableElement = document.querySelectorAll('[data-number="' + toCopyTableNumber + '"]');
            Wall.relevantTablePosition = tableElement[0].getBoundingClientRect().right;
            Wall.copyCurrentDecorElement();

            const data = {'wall': Wall, 'showElement': true, 'buttonsActive': true, 'showColor': true};

            const ajax = new Ajax('ajax-update.php?viewFile=wallColor');
            ajax.post(data, () => {
                Router.pushState(Routes.COLOR_SELECTION);
                Router.changeTitle(PageTitles.COLOR_SELECTION);
                ElementLengthHelper.deactivateAllButOneActiveElement();
            });
        }
    }

    static designOfFreeWallElement(element) {
        element.addEventListener('click', EventHelper.clickOverlayElement);
    }

    static clickOverlayElement(e) {
        let activeWallelement = DocumentSelector.selectAllByClass(ClassNames.WALL_ELEMENT_ACTIVE);
        if (activeWallelement.length > 0) {
            activeWallelement.forEach((element) => {
                element.classList.remove(ClassNames.WALL_ELEMENT_ACTIVE);
            });
        }
        let clickedElementId = e.target.getAttribute('id');
        let overlayElement = clickedElementId.split('-')[0];
        let elementIndex = clickedElementId.substring(overlayElement.length - 1, overlayElement.length);
        EventHelper.changeOfRelevantCassette(elementIndex, clickedElementId);

        const relatedTableNumber = EventHelper.getRelatedTableNumberById(clickedElementId);
        const minusButtonOfTable = DocumentSelector.selectById('confirm-minus-table-' + relatedTableNumber);
        minusButtonOfTable.classList.remove('minus_of');
        minusButtonOfTable.classList.add('minus');
        minusButtonOfTable.addEventListener('click', EventHelper.clickMinusConfirm);

    }

    static changeOfRelevantCassette(index, id) {
        let color = new ColorHelper();

        let cassetteId = id.replace('overlay', 'element');

        let nextIndex = parseInt(index) + 1;
        let nextCassetteId = id.replace('overlay' + index + '', 'element' + nextIndex + '');
        let nextOverlayId = id.replace('overlay' + index + '', 'overlay' + nextIndex + '');

        let previousIndex = parseInt(index) - 1;
        let previousOverlayId = id.replace('overlay' + index + '', 'overlay' + previousIndex + '');
        let hidden = '';

        if (index === '1' || index === '2' || index === '5' || index === '6') {
            hidden = 'hidden1';
        } else {
            hidden = 'hidden2';
        }

        let cassette = document.getElementById(cassetteId);
        cassette.classList.toggle('big');
        color.deleteColorOfCassette(cassette);
        EventHelper.deactivatePlusMinusButtons();

        let nextCassette = document.getElementById(nextCassetteId);
        nextCassette.classList.toggle(hidden);
        color.deleteColorOfCassette(nextCassette);

        if (parseInt(index) > 1) {
            let previousOverlay = document.getElementById(previousOverlayId);
            previousOverlay.classList.toggle(hidden);
        }

        if (parseInt(index) < 7) {
            let nextOverlay = document.getElementById(nextOverlayId);
            nextOverlay.classList.toggle(hidden);
        }
    }

    static activatePlusMinusButtons() {
        let elementPlusButtons = DocumentSelector.selectAllByClass('element-plus');
        if (elementPlusButtons) {
            elementPlusButtons.forEach((element) => {
                element.classList.remove('plus_of');
                element.classList.add('plus');
                element.addEventListener('click', EventHelper.clickPlusConfirm);
            });
        }

        let decorPlusButtons = DocumentSelector.selectAllByClass('decor-plus');
        if (decorPlusButtons) {
            decorPlusButtons.forEach((element) => {
                element.classList.remove('plus_of');
                element.classList.add('plus');
                element.addEventListener('click', EventHelper.clickPlusConfirm);
            });
        }

        let elementMinusButtons = DocumentSelector.selectAllByClass('element-minus');
        if (elementMinusButtons) {
            elementMinusButtons.forEach((element) => {
                element.classList.remove('minus_of');
                element.classList.add('minus');
                element.addEventListener('click', EventHelper.clickMinusConfirm);
            });
        }

        let decorMinusButtons = DocumentSelector.selectAllByClass('decor-minus');
        if (decorMinusButtons) {
            decorMinusButtons.forEach((element) => {
                element.classList.remove('minus_of');
                element.classList.add('minus');
                element.addEventListener('click', EventHelper.clickMinusConfirm);
            });
        }

        let elementCopyButtons = DocumentSelector.selectAllByClass('element-copy');
        if (elementCopyButtons) {
            elementCopyButtons.forEach((element) => {
                element.classList.remove('copy_of');
                element.classList.add('copy');
                element.addEventListener('click', EventHelper.clickCopyConfirm);
            });
        }

        let decorCopyButtons = DocumentSelector.selectAllByClass('decor-copy');
        if (decorCopyButtons) {
            decorCopyButtons.forEach((element) => {
                element.classList.remove('copy_of');
                element.classList.add('copy');
                element.addEventListener('click', EventHelper.clickDecorCopyConfirm);
            });
        }

        let planEndButton = DocumentSelector.selectById('plan-end');
        if (planEndButton && planEndButton.classList.contains('plan-decision-of')) {
            planEndButton.classList.remove('plan-decision-of');
            planEndButton.classList.add('plan-decision');
            planEndButton.addEventListener('click', EventHelper.clickEndButton);
        }

        let planEndButtonEndkunde = DocumentSelector.selectById(DomIds.END_BUTTON_ENDKUNDE);
        if (planEndButtonEndkunde && planEndButtonEndkunde.classList.contains('plan-decision-of')) {
            planEndButtonEndkunde.classList.remove('plan-decision-of');
            planEndButtonEndkunde.classList.add('plan-decision');
            planEndButtonEndkunde.addEventListener('click', EventHelper.clickEndButtonEndkunde);
        }
    }

    static deactivatePlusMinusButtons() {
        let elementPlusButtons = DocumentSelector.selectAllByClass('element-plus');
        if (elementPlusButtons) {
            elementPlusButtons.forEach((element) => {
                element.classList.remove('plus');
                element.classList.add('plus_of');
            });
        }

        let decorPlusButtons = DocumentSelector.selectAllByClass('decor-plus');
        if (decorPlusButtons) {
            decorPlusButtons.forEach((element) => {
                element.classList.remove('plus');
                element.classList.add('plus_of');
            });
        }

        let elementMinusButtons = DocumentSelector.selectAllByClass('element-minus');
        if (elementMinusButtons) {
            elementMinusButtons.forEach((element) => {
                element.classList.remove('minus');
                element.classList.add('minus_of');
            });
        }

        let decorMinusButtons = DocumentSelector.selectAllByClass('decor-minus');
        if (decorMinusButtons) {
            decorMinusButtons.forEach((element) => {
                element.classList.remove('minus');
                element.classList.add('minus_of');
            });
        }

        let elementCopyButtons = DocumentSelector.selectAllByClass('element-copy');
        if (elementCopyButtons) {
            elementCopyButtons.forEach((element) => {
                element.classList.remove('copy');
                element.classList.add('copy_of');
            });
        }

        let decorCopyButtons = DocumentSelector.selectAllByClass('decor-copy');
        if (decorCopyButtons) {
            decorCopyButtons.forEach((element) => {
                element.classList.remove('copy');
                element.classList.add('copy_of');
            });
        }

        let planEndButton = DocumentSelector.selectById('plan-end');
        if (planEndButton && planEndButton.classList.contains('plan-decision')) {
            planEndButton.classList.remove('plan-decision');
            planEndButton.classList.add('plan-decision-of');
        }

        let planEndButtonEndkunde = DocumentSelector.selectById(DomIds.END_BUTTON_ENDKUNDE);
        if (planEndButtonEndkunde && planEndButtonEndkunde.classList.contains('plan-decision')) {
            planEndButtonEndkunde.classList.remove('plan-decision');
            planEndButtonEndkunde.classList.add('plan-decision-of');
        }
    }

    static clickPlanBreak() {
        let elementsLength = Wall.elements.length;

        for (let i = 0; i < elementsLength; i++) {
            Wall.elements.shift();
            Wall.commonLength = 7;
        }

        const selectedType = TypeHelper.getSelectedType();

        selectedType.elementSrc = EventHelper.showSrcOfDefaultElement(TypeHelper.getSelectedTypeNumber());

        selectedType.showTypeSelection = true;

        const ajax = new Ajax('ajax-update.php?viewFile=wallColor&new=true');
        ajax.post(selectedType, () => {
            Router.pushState(Routes.ELEMENT_SELECTION);
            Router.changeTitle(PageTitles.ELEMENT_SELECTION);
        });
    }

    static clickEndButton() {
        Wall.savePillarsInputs();
        const data = {'wall': Wall, 'showSummary': true};
        const ajax = new Ajax('ajax-summary.php?viewFile=wallColor');
        ajax.post(data, () => {
            Router.pushState(Routes.SUMMARY);
            Router.changeTitle(PageTitles.SUMMARY);
        });
    }

    static clickEndButtonEndkunde() {

        let design = 'kr-design';

        let waitingCircle = document.getElementsByClassName('modal-invisible');
        if (waitingCircle) {
            waitingCircle[0].style.display = 'block';
        }

        if (Wall.elements.length === 0) {
            Wall.saveElements();
        }

        if (Wall.pillars.length === 0) {
            Wall.savePillarsInputs();
        }

        const data = {'wall': Wall, 'showSummary': true, 'typeOfDesign': design};
        const ajax = new Ajax('ajax-summary-endkunde.php?viewFile=wallColor');
        ajax.post(data, () => {
            Router.pushState(Routes.SUMMARY);
            Router.changeTitle(PageTitles.SUMMARY);
        });
    }

    static clickToPlan() {
        const data = {'wall': Wall, 'showElement': true, 'buttonsActive': true, 'showColor': true};
        const ajax = new Ajax('ajax-back-to-plan.php');
        ajax.post(data, () => {
            Router.pushState(Routes.COLOR_SELECTION);
            Router.changeTitle(PageTitles.COLOR_SELECTION);
        });
    }

    static clickToOffer() {
        let design = document.querySelector('input[name = "pdf-design"]:checked').value;

        let waitingCircle = document.getElementsByClassName('modal-invisible');
        if (waitingCircle) {
            waitingCircle[0].style.display = 'block';
        }

        const address = CustomerAddress.getAddress();
        const data = {'wall': Wall, 'showOffer': true, 'typeOfDesign': design, 'address': address};
        const ajax = new Ajax('ajax-documents.php?viewFile=wallColor');
        ajax.post(data);
    }

    static clickToSummary() {
        const data = {'wall': Wall, 'showSummary': true};
        const ajax = new Ajax('ajax-summary.php?viewFile=wallColor');
        ajax.post(data, () => {
            Router.pushState(Routes.SUMMARY);
            Router.changeTitle(PageTitles.SUMMARY);
        });
    }

    static scrollToActiveElement() {
        let scrollElement = '';

        if (Wall.relevantTable === Wall.elements.length - 1) {
            scrollElement = 'element-table-' + String(Wall.relevantTable);
        } else {
            scrollElement = 'element-table-' + String(Wall.relevantTable + 1);
        }

        let activeElement = document.getElementById(scrollElement);
        if (activeElement) {
            let scrollField = document.getElementById(DomIds.DRAWING_FIELD);
            const relevantTable = document.querySelectorAll('[data-number="' + Wall.relevantTable + '"]');
            const currentPosOfRelevantTable = relevantTable[0].getBoundingClientRect().right;

            scrollField.scrollLeft = currentPosOfRelevantTable - Wall.relevantTablePosition;
        }
    }

    static clickAnchor(e) {
        let anchorImages = DocumentSelector.selectAllByClass('post-image');
        anchorImages.forEach((element) => {
            if (!element.classList.contains('hidden')) {
                element.classList.add('hidden');
            }
        });

        if (Wall) {
            Wall.savePillarsInputs();
            Wall.save();
        }

        let anchorId = e.target.getAttribute('id');
        let anchorImgId = anchorId.replace('Post', 'Image');
        let image = DocumentSelector.selectById(anchorImgId);
        image.classList.remove('hidden');
    }

    static clickPillarsColor() {
        if (Wall) {
            Wall.savePillarsInputs();
            Wall.saveElements();
            Wall.save();
        }
    }

    static clickProfileColor() {
        if (Wall) {
            Wall.removeStandardElementClasses();
            Wall.savePillarsInputs();
            Wall.saveElements();
            Wall.save();
        }
    }

    static clickPillarsCap() {
        if (Wall) {
            Wall.savePillarsInputs();
            Wall.save();
        }
    }

    static createColorSelector() {
        const colorSelectorNode = DocumentSelector.selectById(DomIds.COLOR_SELECTOR);
        const elementButtons = DocumentSelector.selectAllByClass(DomIds.CASSETTE_ITEMS);
        if (colorSelectorNode && elementButtons.length > 0) {
            const colorSelector = new ColorSelector();
            colorSelector.addColorEvents();
        }
    }

    static scrollToPreviewImage(presentationField) {
        const imgPreview = document.getElementById('previewImage');
        if (imgPreview !== null && imgPreview.getBoundingClientRect().right > presentationField.clientWidth) {
            const scrollField = document.getElementById(DomIds.DRAWING_FIELD);
            let distance = imgPreview.getBoundingClientRect().right - presentationField.clientWidth;
            scrollField.scrollLeft += distance;
        }
    }

    static selectDoorHandleAndElementWidth() {
        const element = DocumentSelector.selectFirstByClass(ClassNames.TABLE_ACTIVE);
        if (element) {
            ElementLengthHelper.hideOrShow(element.children[0]);
            DoorHandleHelper.select(element);
        }
    }
}

export default EventHelper;
