import {
    SmallFenceType1,
    SmallFenceType2,
    SmallFenceType3,
    SmallFenceType4,
    SmallFenceType5,
    SmallFenceType6,
    SmallFenceType7,
    SmallFenceType8,
    SmallFenceType9,
    SmallFenceType10,
    SmallFenceType11,
    SmallFenceType12,
    SmallFenceType13,
    SmallFenceType14,
    SmallFenceType15,
    SmallFenceType16,
    SmallType1,
    SmallType2,
    SmallType3,
    StandardType1,
    StandardType10,
    StandardType11,
    StandardType12,
    StandardType13,
    StandardType14,
    StandardType15,
    StandardType16,
    StandardType17,
    StandardType18,
    StandardType19,
    StandardType2,
    StandardType20,
    StandardType21,
    StandardType3,
    StandardType4,
    StandardType5,
    StandardType6,
    StandardType7,
    StandardType8,
    StandardType9,
    Type1,
    Type2,
    Type3,
    Type4,
    Type5,
    Type6
} from '../enum/Types';
import DocumentSelector from './DocumentSelector';
import WallElement from '../modules/WallElement';
import ColorHelper from './ColorHelper';
import Cassette from '../modules/Cassette';

class TypeHelper {
    setSelectedTypeNumber(selectedType) {
        this.selectedType = selectedType;
    }
    setSmallSelectedTypeNumber(selectedType) {
        this.selectedType = selectedType;
    }

    setIsDoor(isDoor) {
        this.isDoor = isDoor;
    }
    setIsDoubleDoor(isDoubleDoor) {
        this.isDoubleDoor = isDoubleDoor;
    }
    setIsSmallType(isType) {
        this.isSmallType = isType;
    }
    isSmallType() {
        return this.isSmallType;
    }
    isDoor() {
        return this.isDoor;
    }
    isDoubleDoor() {
        return this.isDoubleDoor;
    }

    getSelectedTypeNumber() {
        let type = this.selectedType;
        if(!type){
            type = 2;
        }
        return type;
    }

    getSelectedType() {
        switch (parseInt(this.selectedType)) {
        case 1:
            return Type1;
        case 2:
            return Type2;
        case 3:
            return Type3;
        case 4:
            return Type4;
        case 5:
            return Type5;
        case 6:
            return Type6;
        default:
            return Type2;
        }
    }

    getSmallSelectedType() {
        switch (parseInt(this.selectedType)) {
        case 1:
            return SmallType1;
        case 3:
            return SmallType2;
        case 6:
            return SmallType3;
        }
    }

    getSelectedStandardElement() {
        switch (parseInt(this.selectedStandardElement)) {
        case 1:
            return StandardType1;
        case 2:
            return StandardType2;
        case 3:
            return StandardType3;
        case 4:
            return StandardType4;
        case 5:
            return StandardType5;
        case 6:
            return StandardType6;
        case 7:
            return StandardType7;
        case 8:
            return StandardType8;
        case 9:
            return StandardType9;
        case 10:
            return StandardType10;
        case 11:
            return StandardType11;
        case 12:
            return StandardType12;
        case 13:
            return StandardType13;
        case 14:
            return StandardType14;
        case 15:
            return StandardType15;
        case 16:
            return StandardType16;
        case 17:
            return StandardType17;
        case 18:
            return StandardType18;
        case 19:
            return StandardType19;
        case 20:
            return StandardType20;
        case 21:
            return StandardType21;
        case 22:
            return StandardType22;


        }
    }
    getSelectedSmallFenceElement() {
        switch (parseInt(this.selectedSmallFenceElement)) {
        case 1:
            return SmallFenceType1;
        case 2:
            return SmallFenceType2;
        case 3:
            return SmallFenceType3;
        case 4:
            return SmallFenceType4;
        case 5:
            return SmallFenceType5;
        case 6:
            return SmallFenceType6;
        case 7:
            return SmallFenceType7;
        case 8:
            return SmallFenceType8;
        case 9:
            return SmallFenceType9;
        case 10:
            return SmallFenceType10;
        case 11:
            return SmallFenceType11;
        case 12:
            return SmallFenceType12;
        case 13:
            return SmallFenceType13;
        case 14:
            return SmallFenceType14;
        case 15:
            return SmallFenceType15;
        case 16:
            return SmallFenceType16;
        }
    }

    setSelectedDecorNumber(selectedType){
        this.selectedDecor = selectedType;
    }
    setSelectedKeramikNumber(selectedType){
        this.selectedKeramik = selectedType;
    }
    setSelectedAbschlussNumber(selectedType){
        this.selectedAbschluss = selectedType;
    }
    getSelectedAbschlussNumber(){
        return this.selectedAbschluss ;
    }

    getSelectedDecorNumber(){
        return this.selectedDecor;
    }

    getSelectedKeramikNumber(){
        return this.selectedKeramik;
    }
    setSelectedStandardElementNumber(selectedType){
        this.selectedStandardElement = selectedType;
    }
    setSelectedSmallFenceElementNumber(selectedType){
        this.selectedSmallFenceElement = selectedType;
    }

    generateTypeFromDom(domId) {
        const finishedWallNode = DocumentSelector.selectById(domId);
        if(finishedWallNode){
            const children = finishedWallNode.children;
            const childrenLength = children.length;
            const element = new WallElement(finishedWallNode.className);
            for (let i = 0; i < childrenLength; i++) {
                const childNode = children[i];
                const color = ColorHelper.findColorOfCassette(childNode);
                const visibility = Cassette.getVisibilityClass(childNode);
                const pos = i + 1;
                const cassette = new Cassette(pos, color, visibility);
                element.addCassette(cassette);
            }
            return element;
        }
    }
}
// export class as singleton
export default (new TypeHelper());
