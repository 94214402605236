import TypeSelector from './controller/TypeSelector';
import CookieConsent from './modules/CookieConsent';
import Renderer from './modules/Renderer';
import RetailerSearchEventHelper from './helper/RetailerSearchEventHelper';
import EventHelper from './helper/EventHelper';

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    run();
} else {
    document.addEventListener('DOMContentLoaded', run);
}

function run() {
    const typeSelector = new TypeSelector();

    typeSelector.addConfirmEvent();
    typeSelector.addStartEvent();

    if (isReload()) {
        Renderer.setSettings();
        Renderer.addEventListeners();
        Renderer.addSummaryEventListeners();

        EventHelper.selectDoorHandleAndElementWidth();

        if (isRetailerSearch()) {
            RetailerSearchEventHelper.initGoogleMaps();
        }
    }

    const cookieConsent = new CookieConsent();
    cookieConsent.run();
}


function isReload() {
    const pathName = window.location.pathname;
    return !(pathName.indexOf('haendler') !== -1 || pathName === '/') || isRetailerSearch();
}

function isRetailerSearch() {
    const pathName = window.location.pathname;
    return pathName.indexOf('haendler-suche') !== -1;
}

function initMap() {
    if (isRetailerSearch()) {
        RetailerSearchEventHelper.initGoogleMaps();
    }
}

window.initMap = initMap;
