import DocumentSelector from './DocumentSelector';
import {ClassNames, DomIds, HandlePosition} from '../constants/Constants';

class DoorHandleHelper {
    static hideOrShow(e) {
        const container = DocumentSelector.selectById(DomIds.DOOR_HANDLE_SELECTION);

        if (e.target.parentNode.dataset.door === '1') {
            container.classList.remove(ClassNames.HIDDEN);
        } else {
            container.classList.add(ClassNames.HIDDEN);
        }
    }

    static select(element) {
        if (element.dataset.door === '1') {
            const doorHandleLeft = DocumentSelector.selectById(DomIds.DOOR_HANDLE_LEFT_RADIO);
            const doorHandleRight = DocumentSelector.selectById(DomIds.DOOR_HANDLE_RIGHT_RADIO);
            if (doorHandleLeft && element.dataset.handleposition === HandlePosition.LEFT) {
                doorHandleLeft.checked = true;
            } else if (doorHandleRight) {
                doorHandleRight.checked = true;
            }
        }
    }

    static setFrameColor(color) {
        const doorFrameParts = DocumentSelector.selectAllByClass('door-frame');
        doorFrameParts.forEach((element) => {
            element.style.backgroundColor = color;
        });
    }
}

export default DoorHandleHelper;