export const RetailerSearch = {
    RADIUS: 31.0686, // Meilen
};

export const DoorOrType = {
    DOOR: 'door',
    TYPE: 'type',
    DOUBLE_DOOR: 'double-door'
};

export const HandlePosition = {
    LEFT: 'left',
    RIGHT: 'right'
};

export const DomIds = {
    CONFIRM_BUTTON: 'confirm',
    LOGIN_BUTTON: 'login-button',
    START_PLANNING_BUTTON: 'start-planning-button',
    START_END_CUSTOMER: 'start-end-customer',
    START_STOCKIST: 'start-stockist',
    LOAD_END_CUSTOMER_PLANNING: 'load-end-customer-planning',
    LOAD_STOCKIST_PLANNING: 'load-stockist-planning',
    SHOW_LOAD_PLANNING_BUTTON_END_CUSTOMER: 'show-load-planning-button',
    SHOW_LOAD_PLANNING_BUTTON_STOCKIST: 'toggle-load-configuration-view',
    HIDE_LOAD_PLANNING_BUTTON_STOCKIST: 'toggle-load-configuration-back',
    LOAD_PLANNING_BUTTON_END_CUSTOMER: 'load-planning-button',
    LOAD_PLANNING_BUTTON_STOCKIST: 'load-planning-button-stockist',
    LOAD_PLANNING_BACK_BUTTON: 'load-planning-back-button',
    USER_LOAD_EMAIL: 'email-load',
    USER_LOAD_NUMBER: 'number-load',
    USER_LOAD_PASSWORD: 'password-load',
    ERROR_MESSAGE: 'errormessage',
    DECOR_CONFIRM_BUTTON: 'decor-confirm',
    CONFIRM_COLOR_BUTTON: 'confirm-color',
    CONFIRM_PLUS_BUTTON: 'confirm-plus',
    MAIN: 'main',
    FOOTER: 'footer',
    TYPE_SELECTOR: 'type-selection',
    ELEMENT_SELECTOR: 'element-options',
    ELEMENT_TABS: 'tabreiter',
    COLOR_SELECTOR: 'color-selection',
    WALL_DESIGNER: 'wall-designer',
    FINISHED_WALL: 'finished-wall',
    ELEMENT_ARTICLE: 'element-article',
    CASSETTE_ITEMS: 'cassette-items',
    DECOR_ITEMS: 'decor-item',
    ELEMENT_IMAGE: 'templateImage',
    CONFIRMBUTTON: 'confirmbutton',
    BREAK_BUTTON: 'plan-break',
    NEW_PLAN_BUTTON: 'new-plan-button',
    END_BUTTON: 'plan-end',
    END_BUTTON_ENDKUNDE: 'plan-end-endkunden-version',
    TO_PLAN: 'back-to-plan',
    TO_OFFER: 'go-to-offer',
    TO_SUMMARY: 'to-summary',
    LENGTH_180: 'element-length-180',
    LENGTH_90: 'element-length-90',
    EMAIL: 'email',
    PASSWORD: 'password',
    LOGOUT_ROW: 'logout-row',
    RETAILER_SEARCH: 'retailer-search',
    RETAILER_SEARCH_MAP: 'retailer-search-map',
    RETAILER_SEARCH_BUTTON: 'retailer-search-button',
    RETAILER_SEARCH_ZIP: 'zip-code',
    RETAILER_SEARCH_HINT: 'retailer-search-hint',
    RETAILER_SEARCH_HINT_HEADLINE: 'retailer-search-hint-headline',
    RETAILER_SEARCH_RESULTS: 'retailer-search-results',
    RETAILER_SEARCH_RESULT_LIST: 'retailer-search-result-list',
    RETAILER_SEARCH_ERROR_MESSAGE: 'error-message-retailer-search',
    ERROR_CONTAINER: 'error-container',
    RETAILER_SEARCH_FORM: 'retailer-search-form',
    RETAILER_SEARCH_TO_ORDER: 'to-order-button',
    RETAILER_SEARCH_ERROR_NO_ONE_SELECTED: 'error-message-no-retailer-selected',
    RETAILER_SEARCH_SHOW_TERMS_OF_USE: 'show-terms-of-use',
    RETAILER_SEARCH_TERMS_OF_USE: 'terms-of-use-modal',
    RETAILER_SEARCH_CLOSE_TERMS_OF_USE: 'close-terms-of-use',
    RETAILER_SEARCH_SEND_ORDER: 'send-order-request-button',
    ACCEPT_TERMS_OF_USE: 'accept-terms-of-use',
    DOOR_HANDLE_SELECTION: 'door-handle-selection',
    ELEMENT_LENGTH_HEADER: 'length-header',
    ELEMENT_LENGTH_SELECTOR: 'length-selector',
    DOOR_HANDLE_LEFT_RADIO: 'door-handle-left',
    DOOR_HANDLE_RIGHT_RADIO: 'door-handle-right',
    DRAWING_FIELD: 'presentationField',
    ARROW_GRADIENT_ELEMENT_FORWARD: 'element-gradient-container-forward',
    ARROW_GRADIENT_ELEMENT_BACK: 'element-gradient-container-back',
    SCROLL_MAIN_TAB_FORWARD: 'scroll-main-tab-forward',
    SCROLL_MAIN_TAB_BACK: 'scroll-main-tab-back'
};

export const ElementLengths = {
    ABSCHLUSS_LENGTH: 91.5,
    DECOR_LENGTH: 66.5,
    ELEMENT_BETWEEN_DISTANCE: 1.5,
    DOOR_LENGTH: 104.5
};

export const ClassNames = {
    DOOR: 'door',
    DOUBLE_DOOR: 'double-door',
    DOUBLE_DOOR_LEFT: 'double-door-left',
    DOUBLE_DOOR_RIGHT: 'double-door-right',
    DOUBLE_DOOR_LEFT_HANDLE: 'drawing-doubledoor-handle-left',
    DOUBLE_DOOR_RIGHT_HANDLE: 'drawing-doubledoor-handle-right',
    TYPE: 'type',
    SMALL_TYPE: 'small-type',
    ACTIVE: 'active',
    DECOR: 'decor',
    KERAMIK: 'keramik',
    KERAMIK_ITEM: 'keramik-item',
    ABSCHLUSS: 'abschluss',
    ABSCHLUSS_ITEM: 'abschluss-item',
    SMALL_FENNCE: 'smallfence',
    STANDARD_ELEMENT: 'standard',
    ELEMENT_LENGTH: 'element-length-radio',
    COLOR_SELECTOR: 'color-selector',
    WALL_ELEMENT: 'wallelement',
    WALL_ELEMENT_SMALL: 'wallelement-small',
    WALL_ELEMENT_ACTIVE: 'wallelement-active',
    TABLE_ACTIVE: 'activeTable',
    CONFIRM: 'confirm',
    CONFIRM_OF: 'confirm_of',
    CONFIRM_END_STEP: 'confirm7',
    WALL_ACTIVE: 'wallactive',
    COLOR_ACTIVE: 'color-active',
    C1: 'c1',
    C2: 'c2',
    C3: 'c3',
    C4: 'c4',
    C5: 'c5',
    ALUM: 'alum',
    CORTEN: 'corten',
    EDEL: 'edel',
    HIDDEN: 'hidden',
    HIDDEN1: 'hidden1',
    HIDDEN2: 'hidden2',
    BIG: 'big',
    SMALL: 'wallelement-small',
    SMALLCASSETE: 'small',
    HIDDEN_SMALL: 'hidden-small',
    DECOR_COLOR: 'decor-color',
    OVERLAY: 'overlay',
    PLUS_BUTTON: 'plus',
    ELEMENT_PLUS_BUTTON: 'element-plus',
    DECOR_PLUS_BUTTON: 'decor-plus',
    MINUS_BUTTON: 'minus',
    ELEMENT_COPY_BUTTON: 'element-copy',
    KERAMIK_COPY_BUTTON: 'keramik-copy',
    DECOR_COPY_BUTTON: 'decor-copy',
    POST_ANCHOR: 'post-anchor',
    SCREEN_WIDTH_XS: 'screen-width-xs',
    SCREEN_WIDTH: 'screen-width',
    ADDRESS_INPUTS: 'address-inputs',
    MAIN_NAVIGATION: 'mainnavigation',
    RETAILER_SEARCH_RESULT_ITEM: 'retailer-search-result-item',
    RETAILER_SEARCH_RESULT_COMPANY: 'retailer-search-result-company',
    RETAILER_SEARCH_RESULT_LINKS: 'retailer-search-result-links',
    RETAILER_SEARCH_RESULT_DISTANCE: 'retailer-search-distance',
    RETAILER_SEARCH_RESULT_CHECKBOX: 'retailer-search-checkbox',
    ORDER_REQUEST_INPUT: 'order-request-input',
    INPUT_ERROR: 'input-error',
    LABEL_ERROR: 'label-error',
    SHOW_FLEX: 'show-flex',
    HIDE_FLEX: 'hide-flex',
    DRAWING_DOOR_HANDLE_RIGHT: 'drawing-door-handle-right',
    DRAWING_DOOR_HANDLE_LEFT: 'drawing-door-handle-left',
    DRAWING_DOUBLE_DOOR_HANDLE_RIGHT: 'drawing-double-door-handle-right',
    DRAWING_DOUBLE_DOOR_HANDLE_LEFT: 'drawing-double-door-handle-left',
    OPEN_DOOR_HANDLE_LEFT: 'lefthandle-open-door',
    OPEN_DOOR_HANDLE_RIGHT: 'righthandle-open-door',
    HIGH_FENCE: 'high-fence',
    SMALL_FENCE: 'small-fence',
    LEFT_ABSCHLUSS: 'left-abschluss',
    RIGHT_ABSCHLUSS: 'right-abschluss',
};

export const Nodes = {
    IMG: 'img',
    DEFAULT_TYPE: '2',
};

export const DataSets = {
    RETAILER_SEARCH_RESULT_ITEM: 'resultitem',
    RETAILER_SEARCH_RESULT_COMPANY: 'resultcompany',
    RETAILER_SEARCH_RESULT_EMAIL: 'resultemail',
    RETAILER_SEARCH_RESULT_NAME: 'resultname',
    RETAILER_SEARCH_RESULT_ZIP: 'resultzip',
    RETAILER_SEARCH_RESULT_CITY: 'resultcity',
    RETAILER_SEARCH_RESULT_ADDRESS_1: 'resultaddress1',
    RETAILER_SEARCH_RESULT_ADDRESS_2: 'resultaddress2',
    RETAILER_SEARCH_RESULT_TELEPHONE: 'resulttelephone',
    DOOR: 'door',
    HANDLE_POSITION: 'handleposition',
    TYPE_NUMBER: 'number',
    PROFILE_COLOR: 'profilecolor',
};

export const NodeNames = {
    PROFILE_COLOR: 'profile-color',
};
