import Ajax from '../modules/Ajax';
import Map from '../modules/retailerSearch/Map';
import {PageTitles, Routes} from '../modules/router/Routes';
import Router from '../modules/router/Router';

class RetailerSearchEventHelper {
    static goToRetailerSearch() {
        const ajax = new Ajax('ajax-retailer-search.php?viewFile=retailerSearchStart');
        ajax.post({}, RetailerSearchEventHelper.initGoogleMaps);
    }

    static initGoogleMaps() {
        new Map();
        Router.pushState(Routes.RETAILER_SEARCH);
        Router.changeTitle(PageTitles.RETAILER_SEARCH);
    }
}

export default RetailerSearchEventHelper;