import {ClassNames} from '../constants/Constants';

class AbschlussElement {
    constructor(abschlussElementsPath) {
        const directionClass = abschlussElementsPath.includes('rotated') ? ClassNames.RIGHT_ABSCHLUSS : ClassNames.LEFT_ABSCHLUSS;
        this.abschlussPath = [abschlussElementsPath];
        this.type = 'abschluss';
        this.className = 'abschluss-item ' + directionClass;
    }
}

export default AbschlussElement;