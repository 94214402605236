import WallElement from './WallElement';

class FreeWallElement extends WallElement {
    constructor(className, isSmallFence) {
        super(className);
        if(isSmallFence) {
            this.overlay = ['overlay5', 'overlay6', 'overlay7'];
        } else {
            this.overlay = ['overlay1', 'overlay2', 'overlay3', 'overlay4', 'overlay5', 'overlay6', 'overlay7'];
        }
    }

    addCassette(cassette) {
        super.addCassette(cassette);
    }
}

export default FreeWallElement;