class PillarsElement {
    constructor() {
        this.color = '';
        this.anchor = '';
        this.accessory = true;
    }

    addColor(color){
        this.color = color;
    }

    addAnchor(anchor){
        this.anchor = anchor;
    }

    addAccessory(accessory){
        this.accessory = accessory;
    }
}

export default PillarsElement;