class Location {
    constructor(marker) {
        this.name = marker.getAttribute('name');
        this.companyName = marker.getAttribute('company_name');
        this.address_1 = marker.getAttribute('address_1');
        this.address_2 = marker.getAttribute('address_2');
        this.zip = marker.getAttribute('zip');
        this.city = marker.getAttribute('city');
        this.telephone = marker.getAttribute('telephone');
        this.email = marker.getAttribute('email');
        this.lat = marker.getAttribute('lat');
        this.lng = marker.getAttribute('lng');
        this.distance = marker.getAttribute('distance') / 0.621371; // Meilen zu km
    }
}

export default Location;