import {ClassNames, DomIds} from "../constants/Constants";
import DocumentSelector from "./DocumentSelector";

class ColorHelper {
    constructor() {
        this.clickedWall = DocumentSelector.selectFirstByClass(ClassNames.WALL_ELEMENT_ACTIVE);
        this.colorClasses = [
            ClassNames.C1,
            ClassNames.C2,
            ClassNames.C3,
            ClassNames.C4,
            ClassNames.ALUM,
            ClassNames.CORTEN,
            ClassNames.EDEL,
        ];

    }

    deleteAllColorClasses() {
        this.colorClasses.forEach((element) => {
            this.activeWall.classList.remove(element);
        });
    }

    addColorClass(colorId) {
        if (this.clickedWall) {
            this.deleteColorOfCassette(this.clickedWall);
            this.clickedWall.classList.add(colorId);
            // this.countOfColoredCassettes++;
        }
        // } else if (this.activeWall) {
        //     this.activeWall.classList.add(colorId);
        // }
    }

    deleteAllActiveColorClasses() {
        const colorButtons = DocumentSelector.selectAllByClass(ClassNames.COLOR_SELECTOR);
        colorButtons.forEach((element) => {
            element.classList.remove(ClassNames.COLOR_ACTIVE);
        });
    }

    setActiveColor(target) {
        target.classList.add(ClassNames.COLOR_ACTIVE);
    }

    deleteColorOfCassette(target) {
        this.colorClasses.forEach((element) => {
            if (target.classList.contains(element)) {
                target.classList.remove(element);
            }
        });
    }

    setActiveFlag(target) {
        if(this.clickedWall){
            this.clickedWall.classList.remove(ClassNames.WALL_ELEMENT_ACTIVE);
        }
        target.classList.add(ClassNames.WALL_ELEMENT_ACTIVE);
        this.clickedWall = target;
        this.showActiveColor(this.clickedWall);
    }

    showActiveColor(activeCasset){
        this.colorClasses.forEach((element) => {
            let colorButton = DocumentSelector.selectById(element);
            if(activeCasset.classList.contains(element)){
                colorButton.classList.add('color-active');
            }else {
                colorButton.classList.remove('color-active');
            }
        });
    }

    activateColorConfirm(tableNumber) {
        const colorConfirmButtons = DocumentSelector.selectAllByClass(ClassNames.CONFIRM_OF);

        colorConfirmButtons.forEach((element)=>{
            if(element.id.indexOf(tableNumber)>=0){
                element.classList.remove(ClassNames.CONFIRM_OF);
                element.classList.add(ClassNames.CONFIRM);
            }
        });
    }

    activateNextWall() {
        if (this.activeWall) {
            this.setDefaultColorIfActivaWallHasNoColor();
            const activeWallId = this.activeWall.id;
            let activeWallNumber = parseInt(activeWallId.substr(activeWallId.length - 1));
            this.activeWall.classList.remove(ClassNames.WALL_ACTIVE);
            let newActiveWallId = activeWallNumber + 1;
            let newActiveWall = DocumentSelector.selectById('element' + newActiveWallId);

            if (ColorHelper.newActiveWallIsHidden(newActiveWall)) {
                newActiveWallId++;
                newActiveWall = DocumentSelector.selectById('element' + newActiveWallId);
            }

            if (newActiveWall) {
                newActiveWall.classList.add(ClassNames.WALL_ACTIVE);

            }

            this.activeWall = newActiveWall;
            if (this.activeWall === null) {
                const colorConfirm = DocumentSelector.selectById(DomIds.CONFIRM_COLOR_BUTTON);
                colorConfirm.classList.add(ClassNames.CONFIRM_END_STEP)
            }
        }
    }

    setDefaultColorIfActivaWallHasNoColor() {
        let hasNoColor = true;
        this.colorClasses.forEach((color) => {
            if (this.activeWall.classList.contains(color)) {
                hasNoColor = false;
            }
        });

        if (hasNoColor) {
            const activeColor = DocumentSelector.selectFirstByClass(ClassNames.COLOR_ACTIVE);
            this.activeWall.classList.add(activeColor.id);
        }
    }

    static newActiveWallIsHidden(newActiveWall) {
        return newActiveWall.classList.contains(ClassNames.HIDDEN1) || newActiveWall.classList.contains(ClassNames.HIDDEN2);
    }

    hideShowDecorColors() {
        const decorColors = DocumentSelector.selectAllByClass(ClassNames.DECOR_COLOR);
        if (this.clickedWall.classList.contains(ClassNames.BIG)) {
            this.hideHtmlNodes(decorColors);
        } else {
            this.showHtmlNodes(decorColors);
        }
    }

    hideHtmlNodes(nodes) {
        nodes.forEach((node) => {
            node.classList.add(ClassNames.HIDDEN)
        });
    }

    showHtmlNodes(nodes) {
        nodes.forEach((node) => {
            node.classList.remove(ClassNames.HIDDEN)
        });
    }

    getCountOfCassetteElements(tableNumber){
        let countOfCassette = 0;

        if(this.clickedWall) {
            const nrOfTable = tableNumber.replace('table-', '');
            const walls = document.querySelectorAll('[data-number="' + nrOfTable + '"]');

            walls.forEach((wall) => {
                wall.children.forEach((node) => {
                    if (node.classList.contains(ClassNames.WALL_ELEMENT)
                        && !node.classList.contains('hidden1')
                        && !node.classList.contains('hidden2')) {
                        countOfCassette++;
                    }
                });
            });
        }

        return  countOfCassette;
    }

    getCountOfColoredCassettes(tableNumber){
        let countOfColoredCassette = 0;

        if(this.clickedWall){
            const nrOfTable = tableNumber.replace('table-', '');
            const walls = document.querySelectorAll('[data-number="' + nrOfTable + '"]');
            walls.forEach((wall) => {
                wall.children.forEach((node) => {
                    if (node.classList.contains(ClassNames.WALL_ELEMENT)) {
                        this.colorClasses.forEach((element) => {
                            if (node.classList.contains(element) && node.id.indexOf(tableNumber) >= 0) {
                                countOfColoredCassette++;
                            }
                        });
                    }
                });
            });
        }

        return countOfColoredCassette;
    }

    static findColorOfCassette(casseteNode) {
        let color ='';
        const classList = casseteNode.classList;
        for (let className in classList) {
            switch (classList[className]) {
                case ClassNames.C1:
                    color = ClassNames.C1;
                    break;
                case ClassNames.C2:
                    color = ClassNames.C2;
                    break;
                case ClassNames.C3:
                    color = ClassNames.C3;
                    break;
                case ClassNames.C4:
                    color = ClassNames.C4;
                    break;
                case ClassNames.C5:
                    color = ClassNames.C5;
                    break;
                case ClassNames.C6:
                    color = ClassNames.C6;
                    break;
                case ClassNames.ALUM:
                    color = ClassNames.ALUM;
                    break;
                case ClassNames.CORTEN:
                    color = ClassNames.CORTEN;
                    break;
                case ClassNames.EDEL:
                    color = ClassNames.EDEL;
                    break;
            }
        }

        return color;
    }

    static activateColorTab(){
        let colorTab = DocumentSelector.selectById('color-elements');
        if(colorTab){
            colorTab.checked = true;
        }
    }
}

export default ColorHelper;
