class BrowserCheck {
    static checkBroserOfUser(){
        return navigator.appName;
    }

    static isBrowserIE(){
        if((window.navigator.userAgent.indexOf('MSIE ') !== -1) || (!!document.documentMode === true ) || window.navigator.userAgent.indexOf('Edge/') !== -1){
            return true;
        }else {
            return false;
        }
    }

    static forEachForBrowserIE(){
        if((navigator.userAgent.indexOf('MSIE') !== -1) || (!!document.documentMode === true ) || navigator.userAgent.indexOf("Edge" !== -1)){

            if (window.NodeList && !NodeList.prototype.forEach) {
                NodeList.prototype.forEach = Array.prototype.forEach;
            }

            if (window.HTMLCollection && !HTMLCollection.prototype.forEach) {
                HTMLCollection.prototype.forEach = Array.prototype.forEach;
            }
        }
    }

    static objectValueForBrowserIE(obj){
        const children = Object.keys(obj).map(function (e) {
            return obj[e];
        });

        return children;
    }
}

export default BrowserCheck;