class UrlHelper {
    static getUpdateViewUrl(type) {
        let getParam = '?viewFile=wallColor';
        if (type.editable) {
            getParam = '?viewFile=wallDesign';
        }
        return 'ajax-update.php' + getParam;
    }

    static getAddWallElementUrl() {
        return 'ajax-add-wall.php?viewFile=showWall';
    }

    static getBaseUrl() {
        return window.location.protocol + '//' + window.location.host + '/';
    }

    static generateUrl(urlPart) {
        return window.location.protocol + '//' + window.location.host + '/' + urlPart;
    }
}

export default UrlHelper;
