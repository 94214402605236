import {ClassNames as Constants, ClassNames, DomIds} from '../constants/Constants';
import DocumentSelector from './DocumentSelector';
import BrowserCheck from './BrowserCheck';
import Wall from '../modules/Wall';

class ElementLengthHelper {
    static changeElementLengthClass(length) {
        const wallTemplateNode = DocumentSelector.selectById(DomIds.ELEMENT_IMAGE);
        const activeCassette = DocumentSelector.selectFirstByClass(ClassNames.WALL_ELEMENT_ACTIVE);
        let wallElementNode = '';

        if (activeCassette) {
            wallElementNode = activeCassette.closest('article');
        } else {
            let tableElements = DocumentSelector.selectAllByClass('table-element');

            if (tableElements.length > 0) {
                let i = 0;
                for (i; i < tableElements.length; i++) {
                    let dataValue = tableElements[i].getAttribute('data-number');

                    if (parseInt(dataValue) <= parseInt(Wall.relevantTable) || parseInt(dataValue) === parseInt(Wall.relevantTable) + 1) {
                        wallElementNode = tableElements[i];
                    }
                }
            }
        }


        if (wallTemplateNode) {
            for (let className of wallTemplateNode.classList) {
                if (className.indexOf('image-length') > -1) {
                    wallTemplateNode.classList.remove(className);
                }
            }
            const newClassName = 'image-length-' + length;
            wallTemplateNode.classList.add(newClassName);
        }

        if (wallElementNode) {
            for (let className of wallElementNode.classList) {
                if (className.indexOf('length') > -1) {
                    wallElementNode.classList.remove(className);
                }
            }
            const newClassName = 'length-' + length;

            wallElementNode.classList.add(newClassName);

            this.setCassettesWidth();
        }
    }

    static setCassettesWidth() {
        let smallwallelements = DocumentSelector.selectAllByClass('wallelement-small');
        let wallelements = DocumentSelector.selectAllByClass('wallelement');

        BrowserCheck.forEachForBrowserIE();

        smallwallelements.forEach((element) => {

            let elementWidth = '';
            let parent = element.parentNode;

            if (parent.classList.contains('length-90')) {
                // elementWidth = '88.5%';
                elementWidth = '90.5%';
            } else if (parent.classList.contains('length-180')) {
                // elementWidth = '93.5%';
                elementWidth = '95.2%';
            }

            element.style.width = elementWidth;
        });
        wallelements.forEach((element) => {

            let elementWidth = '';
            let parent = element.parentNode;

            if (parent.classList.contains('length-90')) {
                // elementWidth = '88.5%';
                elementWidth = '90.5%';
            } else if (parent.classList.contains('length-180') && !(parent.classList.contains(Constants.DOUBLE_DOOR_LEFT))) {
                // elementWidth = '93.5%';
                elementWidth = '95.2%';
            } else {
                elementWidth = '97.2%';
            }

            element.style.width = elementWidth;
        });

    }

    static checkLengthOfActiveCassette(e) {
        let parent = e.target.parentNode;
        let length180 = DocumentSelector.selectById(DomIds.LENGTH_180);
        let length90 = DocumentSelector.selectById(DomIds.LENGTH_90);

        if (parent.classList.contains('length-180')) {
            length180.checked = true;
        } else if (parent.classList.contains('length-90')) {
            length90.checked = true;
        }
    }

    static hideOrShow(target) {
        const header = DocumentSelector.selectById(DomIds.ELEMENT_LENGTH_HEADER);
        const selector = DocumentSelector.selectById(DomIds.ELEMENT_LENGTH_SELECTOR);

        if (header && selector) {
            if (target.parentNode.dataset.door === '1') {
                header.classList.add(ClassNames.HIDDEN);
                selector.classList.add(ClassNames.HIDDEN);
            } else {
                header.classList.remove(ClassNames.HIDDEN);
                selector.classList.remove(ClassNames.HIDDEN);
            }
        }

    }

    static deactivateAllButOneActiveElement() {
        const activeElements = DocumentSelector.selectAllByClass(ClassNames.TABLE_ACTIVE);
        if (activeElements && activeElements.length > 1) {
            for (let i = 0; i < activeElements.length - 1; i++) {
                activeElements[i].classList.remove(ClassNames.TABLE_ACTIVE);
            }

        }
    }
}

export default ElementLengthHelper;
