import DocumentSelector from '../helper/DocumentSelector';
import {ClassNames, DomIds} from '../constants/Constants';
import EventHelper from '../helper/EventHelper';

class TypeSelector {
    addTypeEvents() {
        const typeButtons = DocumentSelector.selectAllByClass(ClassNames.TYPE);
        typeButtons.forEach((element) => {
            EventHelper.addTypeClickHandler(element);
        });
        this.addSmallTypeEvents();
    }
    addSmallTypeEvents() {
        const typeButtons = DocumentSelector.selectAllByClass(ClassNames.SMALL_TYPE);
        typeButtons.forEach((element) => {
            EventHelper.addSmallTypeClickHandler(element);
        });
    }
    addDoorEvents() {
        const doorButtons = DocumentSelector.selectAllByClass(ClassNames.DOOR);
        doorButtons.forEach((element) => {
            EventHelper.addDoorClickHandler(element);
        });

        const doorHandleLeft = DocumentSelector.selectById(DomIds.DOOR_HANDLE_LEFT_RADIO);
        const doorHandleRight = DocumentSelector.selectById(DomIds.DOOR_HANDLE_RIGHT_RADIO);
        EventHelper.addDoorHandleClickHandler(doorHandleLeft);
        EventHelper.addDoorHandleClickHandler(doorHandleRight);
        this.addDoubleDoorEvents();
    }
    addDoubleDoorEvents() {
        const doorButtons = DocumentSelector.selectAllByClass(ClassNames.DOUBLE_DOOR);
        doorButtons.forEach((element) => {
            EventHelper.addDoubeDoorClickHandler(element);
        });
    }
    addDecorEvents() {
        const typeButtons = DocumentSelector.selectAllByClass(ClassNames.DECOR);
        typeButtons.forEach((element) => {
            EventHelper.addDecorClickHandler(element);
        });
    }
    addKeramikEvents() {
        const typeButtons = DocumentSelector.selectAllByClass(ClassNames.KERAMIK);
        typeButtons.forEach((element) => {
            EventHelper.addKeramikClickHandler(element);
        });
    }
    addAbschlussEvents() {
        const typeButtons = DocumentSelector.selectAllByClass(ClassNames.ABSCHLUSS);
        typeButtons.forEach((element) => {
            EventHelper.addAbschlussClickHandler(element);
        });
        this.addSmallFenceEvents();
    }
    addSmallFenceEvents() {
        const typeButtons = DocumentSelector.selectAllByClass(ClassNames.SMALL_FENNCE);
        typeButtons.forEach((element) => {
            EventHelper.addSmallFenceClickHandler(element);
        });
    }

    addStandardTypeEvents() {
        const typeButtons = DocumentSelector.selectAllByClass(ClassNames.STANDARD_ELEMENT);
        typeButtons.forEach((element) => {
            EventHelper.addStandardElementClickHandler(element);
        });
    }

    addConfirmEvent() {
        const confirmButton = DocumentSelector.selectById(DomIds.LOGIN_BUTTON);
        if (confirmButton) {
            EventHelper.addConfirmEvent(confirmButton);
        }
    }

    addStartEvent() {
        EventHelper.addStartEvent();
        EventHelper.addShowLoadEvent();
        EventHelper.addBackLoadEvent();
        EventHelper.addLoadEvent();
    }

    makeTitleVisible(title) {
        const elementsTitle = DocumentSelector.selectAllByClass('element-title');
        elementsTitle.forEach((element) => {
            if (element.getAttribute('id') === title || element.getAttribute('checked') === 'checked') {
                element.classList.remove('hidden');
            } else {
                if (!element.classList.contains('hidden')) {
                    element.classList.add('hidden');
                }
            }
        });
    }
}

export default TypeSelector;
