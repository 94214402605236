export const Type1 = {
    element1: 'big',
    element2: 'hidden1',
    element3: 'big',
    element4: 'hidden2',
    element5: 'big',
    element6: 'hidden1',
    element7: 'big',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};

export const Type2 = {
    element1: 'big',
    element2: 'hidden1',
    element3: 'small',
    element4: 'big',
    element5: 'hidden2',
    element6: 'small',
    element7: 'big',
    element8: 'hidden1',
    editable: false,
    elementLength: 180,
};

export const Type3 = {
    element1: 'small',
    element2: 'small',
    element3: 'big',
    element4: 'hidden2',
    element5: 'big',
    element6: 'hidden1',
    element7: 'big',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};
export const Type4 = {
    element1: 'small',
    element2: 'small',
    element3: 'small',
    element4: 'big',
    element5: 'hidden2',
    element6: 'small',
    element7: 'small',
    element8: 'small',
    editable: false,
    elementLength: 180,
};

export const Type5 = {
    element1: 'small',
    element2: 'small',
    element3: 'small',
    element4: 'small',
    element5: 'small',
    element6: 'small',
    element7: 'small',
    element8: 'small',
    editable: false,
    elementLength: 180,
};

export const Type6 = {
    element1: 'small',
    element2: 'small',
    element3: 'small',
    element4: 'small',
    element5: 'small',
    element6: 'small',
    element7: 'small',
    element8: 'small',
    editable: true,
    elementLength: 180,
};

export const SmallType1 = {
    element1: 'hidden-small',
    element2: 'hidden-small',
    element3: 'hidden-small',
    element4: 'hidden-small',
    element5: 'big S1',
    element6: 'hidden1',
    element7: 'big S1',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};

export const SmallType2 = {
    element1: 'hidden-small',
    element2: 'hidden-small',
    element3: 'hidden-small',
    element4: 'hidden-small',
    element5: 'small S1',
    element6: 'small S1',
    element7: 'hidden1',
    element8: 'big S1',
    editable: false,
    elementLength: 180,
};

export const SmallType3 = {
    element1: '',
    element2: '',
    element3: '',
    element4: '',
    element5: 'small S1',
    element6: 'small S1',
    element7: 'small S1',
    element8: 'small S1',
    editable: true,
    elementLength: 180,
};
export const StandardType1 = {
    element1: 'big c1',
    element2: 'hidden1',
    element3: 'big c1',
    element4: 'hidden2',
    element5: 'big c1',
    element6: 'hidden1',
    element7: 'big c1',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};

export const StandardType2 = {
    element1: 'big c3',
    element2: 'hidden1',
    element3: 'big c3',
    element4: 'hidden2',
    element5: 'big c3',
    element6: 'hidden1',
    element7: 'big c3',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};

export const StandardType3 = {
    element1: 'big c2',
    element2: 'hidden1',
    element3: 'big c2',
    element4: 'hidden2',
    element5: 'big c2',
    element6: 'hidden1',
    element7: 'big c2',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};

export const StandardType4 = {
    element1: 'big c4',
    element2: 'hidden1',
    element3: 'big c4',
    element4: 'hidden2',
    element5: 'big c4',
    element6: 'hidden1',
    element7: 'big c4',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};

export const StandardType5 = {
    element1: 'big c4',
    element2: 'hidden1',
    element3: 'big c2',
    element4: 'hidden2',
    element5: 'big c2',
    element6: 'hidden1',
    element7: 'big c4',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};

export const StandardType6 = {
    element1: 'big c1',
    element2: 'hidden1',
    element3: 'big c3',
    element4: 'hidden2',
    element5: 'big c3',
    element6: 'hidden1',
    element7: 'big c1',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};

export const StandardType7 = {
    element1: 'big c1',
    element2: 'hidden1',
    element3: 'small c1',
    element4: 'big c1',
    element5: 'hidden2',
    element6: 'small c1',
    element7: 'big c1',
    element8: 'hidden1',
    editable: false,
    elementLength: 180,
};

export const StandardType8 = {
    element1: 'big c2',
    element2: 'hidden1',
    element3: 'small c1',
    element4: 'big c2',
    element5: 'hidden2',
    element6: 'small c1',
    element7: 'big c2',
    element8: 'hidden1',
    editable: false,
    elementLength: 180,
};

export const StandardType9 = {
    element1: 'big c1',
    element2: 'hidden1',
    element3: 'small c4',
    element4: 'big c1',
    element5: 'hidden2',
    element6: 'small c4',
    element7: 'big c1',
    element8: 'hidden1',
    editable: false,
    elementLength: 180,
};

export const StandardType10 = {
    element1: 'small c1',
    element2: 'small alum',
    element3: 'big c1',
    element4: 'hidden2',
    element5: 'big c1',
    element6: 'hidden1',
    element7: 'big c1',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};

export const StandardType11 = {
    element1: 'small c2',
    element2: 'small alum',
    element3: 'big c2',
    element4: 'hidden2',
    element5: 'big c2',
    element6: 'hidden1',
    element7: 'big c2',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};

export const StandardType12 = {
    element1: 'small c3',
    element2: 'small alum',
    element3: 'big c3',
    element4: 'hidden2',
    element5: 'big c3',
    element6: 'hidden1',
    element7: 'big c3',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};

export const StandardType13 = {
    element1: 'small c4',
    element2: 'small alum',
    element3: 'big c4',
    element4: 'hidden2',
    element5: 'big c4',
    element6: 'hidden1',
    element7: 'big c4',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};

export const StandardType14 = {
    element1: 'small c1',
    element2: 'small corten',
    element3: 'big c1',
    element4: 'hidden2',
    element5: 'big c1',
    element6: 'hidden1',
    element7: 'big c1',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};

export const StandardType15 = {
    element1: 'small c2',
    element2: 'small corten',
    element3: 'big c2',
    element4: 'hidden2',
    element5: 'big c2',
    element6: 'hidden1',
    element7: 'big c2',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};

export const StandardType16 = {
    element1: 'small c3',
    element2: 'small corten',
    element3: 'big c3',
    element4: 'hidden2',
    element5: 'big c3',
    element6: 'hidden1',
    element7: 'big c3',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};

export const StandardType17 = {
    element1: 'small c4',
    element2: 'small corten',
    element3: 'big c4',
    element4: 'hidden2',
    element5: 'big c4',
    element6: 'hidden1',
    element7: 'big c4',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};

export const StandardType18 = {
    element1: 'small c1',
    element2: 'small edel',
    element3: 'big c1',
    element4: 'hidden2',
    element5: 'big c1',
    element6: 'hidden1',
    element7: 'big c1',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};

export const StandardType19 = {
    element1: 'small c2',
    element2: 'small edel',
    element3: 'big c2',
    element4: 'hidden2',
    element5: 'big c2',
    element6: 'hidden1',
    element7: 'big c2',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};

export const StandardType20 = {
    element1: 'small c3',
    element2: 'small edel',
    element3: 'big c3',
    element4: 'hidden2',
    element5: 'big c3',
    element6: 'hidden1',
    element7: 'big c3',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};

export const StandardType21 = {
    element1: 'small c4',
    element2: 'small edel',
    element3: 'big c4',
    element4: 'hidden2',
    element5: 'big c4',
    element6: 'hidden1',
    element7: 'big c4',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};

// small fences code
export const SmallFenceType1 = {
    element1: 'hidden-small',
    element2: 'hidden-small',
    element3: 'hidden-small',
    element4: 'hidden-small',
    element5: 'big c1',
    element6: 'hidden1',
    element7: 'big c1',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};

export const SmallFenceType2 = {
    element1: 'hidden-small',
    element2: 'hidden-small',
    element3: 'hidden-small',
    element4: 'hidden-small',
    element5: 'big c3',
    element6: 'hidden1',
    element7: 'big c3',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};

export const SmallFenceType3 = {
    element1: 'hidden-small',
    element2: 'hidden-small',
    element3: 'hidden-small',
    element4: 'hidden-small',
    element5: 'big c2',
    element6: 'hidden1',
    element7: 'big c2',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};

export const SmallFenceType4 = {
    element1: 'hidden-small',
    element2: 'hidden-small',
    element3: 'hidden-small',
    element4: 'hidden-small',
    element5: 'big c4',
    element6: 'hidden1',
    element7: 'big c4',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};

export const SmallFenceType5 = {
    element1: 'hidden-small',
    element2: 'hidden-small',
    element3: 'hidden-small',
    element4: 'hidden-small',
    element5: 'small c1',
    element6: 'small alum',
    element7: 'big c1',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};
export const SmallFenceType6 = {
    element1: 'hidden-small',
    element2: 'hidden-small',
    element3: 'hidden-small',
    element4: 'hidden-small',
    element5: 'small c2',
    element6: 'small alum',
    element7: 'big c2',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};
export const SmallFenceType7 = {
    element1: 'hidden-small',
    element2: 'hidden-small',
    element3: 'hidden-small',
    element4: 'hidden-small',
    element5: 'small c3',
    element6: 'small alum',
    element7: 'big c3',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};
export const SmallFenceType8 = {
    element1: 'hidden-small',
    element2: 'hidden-small',
    element3: 'hidden-small',
    element4: 'hidden-small',
    element5: 'small c4',
    element6: 'small alum',
    element7: 'big c4',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};


export const SmallFenceType9 = {
    element1: 'hidden-small',
    element2: 'hidden-small',
    element3: 'hidden-small',
    element4: 'hidden-small',
    element5: 'small c1',
    element6: 'small corten',
    element7: 'big c1',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};
export const SmallFenceType10 = {
    element1: 'hidden-small',
    element2: 'hidden-small',
    element3: 'hidden-small',
    element4: 'hidden-small',
    element5: 'small c2',
    element6: 'small corten',
    element7: 'big c2',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};
export const SmallFenceType11 = {
    element1: 'hidden-small',
    element2: 'hidden-small',
    element3: 'hidden-small',
    element4: 'hidden-small',
    element5: 'small c3',
    element6: 'small corten',
    element7: 'big c3',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};
export const SmallFenceType12 = {
    element1: 'hidden-small',
    element2: 'hidden-small',
    element3: 'hidden-small',
    element4: 'hidden-small',
    element5: 'small c4',
    element6: 'small corten',
    element7: 'big c4',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};

export const SmallFenceType13 = {
    element1: 'hidden-small',
    element2: 'hidden-small',
    element3: 'hidden-small',
    element4: 'hidden-small',
    element5: 'small c1',
    element6: 'small edel',
    element7: 'big c1',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};
export const SmallFenceType14 = {
    element1: 'hidden-small',
    element2: 'hidden-small',
    element3: 'hidden-small',
    element4: 'hidden-small',
    element5: 'small c2',
    element6: 'small edel',
    element7: 'big c2',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};
export const SmallFenceType15 = {
    element1: 'hidden-small',
    element2: 'hidden-small',
    element3: 'hidden-small',
    element4: 'hidden-small',
    element5: 'small c3',
    element6: 'small edel',
    element7: 'big c3',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};
export const SmallFenceType16 = {
    element1: 'hidden-small',
    element2: 'hidden-small',
    element3: 'hidden-small',
    element4: 'hidden-small',
    element5: 'small c4',
    element6: 'small edel',
    element7: 'big c4',
    element8: 'hidden2',
    editable: false,
    elementLength: 180,
};
