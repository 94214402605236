import UrlHelper from '../../helper/UrlHelper';
import {PageTitles} from './Routes';

class Router {
    static pushState(route) {
        history.pushState(null, '', UrlHelper.generateUrl(route));
    }

    static changeTitle(title) {
        return document.title = PageTitles.PREFIX + title;
    }
}

export default Router;