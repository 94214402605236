class CookieConsent {
    constructor() {
        this.cookieKey = 'cookieConsentClicked';
        this.hidden = 'hidden';
    }

    run() {
        const cookieContent = this.getCookie(this.cookieKey);
        const cookieBanner = document.getElementById('cookie-banner');

        if (cookieBanner) {
            if (cookieContent) {
                cookieBanner.classList.add(this.hidden);
            } else {
                cookieBanner.classList.remove(this.hidden);
            }

            const cookieConsent = document.querySelector('.ch-cookie-consent');
            const cookieConsentForm = document.querySelector('.ch-cookie-consent__form');
            const cookieConsentFormBtn = document.querySelectorAll('.ch-cookie-consent__btn');
            const cookieConsentCategoryDetails = document.querySelector('.ch-cookie-consent__category-group');
            const cookieConsentCategoryDetailsToggle = document.querySelector('.ch-cookie-consent__toggle-details');

            // If cookie consent is direct child of body, assume it should be placed on top of the site pushing down the rest of the website
            if (cookieConsent && cookieConsent.parentNode.nodeName === 'BODY') {
                if (cookieConsent.classList.contains('ch-cookie-consent--top')) {
                    document.body.style.marginTop = cookieConsent.offsetHeight + 'px';
                    cookieConsent.style.position = 'absolute';
                    cookieConsent.style.top = '0';
                    cookieConsent.style.left = '0';
                } else {
                    document.body.style.marginBottom = cookieConsent.offsetHeight + 'px';

                    cookieConsent.style.position = 'fixed';
                    cookieConsent.style.bottom = '0';
                    cookieConsent.style.left = '0';
                }
            }

            if (cookieConsentForm) {
                // Submit form via ajax
                const length = cookieConsentFormBtn.length;
                for (let i = 0; i < length; i++) {
                    const btn = cookieConsentFormBtn[i];
                    btn.addEventListener('click', (event) => {
                        this.setCookie(this.cookieKey, event.target.id, 30);
                        cookieBanner.classList.add(this.hidden);
                    }, false);
                }
            }

            if (cookieConsentCategoryDetails && cookieConsentCategoryDetailsToggle) {
                cookieConsentCategoryDetailsToggle.addEventListener('click', function () {
                    const detailsIsHidden = cookieConsentCategoryDetails.style.display !== 'block';
                    cookieConsentCategoryDetails.style.display = detailsIsHidden ? 'block' : 'none';
                    cookieConsentCategoryDetailsToggle.querySelector('.ch-cookie-consent__toggle-details-hide').style.display = detailsIsHidden ? 'block' : 'none';
                    cookieConsentCategoryDetailsToggle.querySelector('.ch-cookie-consent__toggle-details-show').style.display = detailsIsHidden ? 'none' : 'block';
                });
            }
        }

    }


    setCookie(name, value, days) {
        let expires = '';
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = '; expires=' + date.toUTCString();
        }
        document.cookie = name + '=' + (value || '') + expires + '; path=/';
    }

    getCookie(name) {
        const nameEQ = name + '=';
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    eraseCookie(name) {
        document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
}

export default CookieConsent;