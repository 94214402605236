class KeramikElement {
    constructor() {
        this.keramikPath = [];
        this.type = 'keramik';
        this.isDoor = false;
        this.className = '';
    }
}

export default KeramikElement;
