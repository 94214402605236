import DocumentSelector from '../../helper/DocumentSelector';
import {ClassNames, DomIds} from '../../constants/Constants';
import Renderer from '../Renderer';

class SendOrderValidator {
    constructor() {
        Renderer.hideErrorMessage();
        this.unmarkErrorInputs();
        this.invalidInput = [];
        this.requiredError = false;
        this.emailError = false;
        this.termsOfUseError = false;
    }

    validateForm() {
        let valid = true;
        const inputs = DocumentSelector.selectAllByClass(ClassNames.ORDER_REQUEST_INPUT);
        inputs.forEach(input => {
            if(input.required && input.value === '') {
                this.invalidInput.push(input);
                this.requiredError = true;
                valid = false;
            }
        });

        if (valid) {
            this.emailError = this.validateEmail();
            valid = !this.emailError;
        }

        if (valid) {
            const checkbox = DocumentSelector.selectById(DomIds.ACCEPT_TERMS_OF_USE);
            this.termsOfUseError = !checkbox.checked;
            valid = !this.termsOfUseError;
            this.invalidInput.push(checkbox);
        }

        return valid;
    }

    showErrorMessages() {
        if (this.requiredError) {
            Renderer.renderErrorMessage('Bitte füllen Sie die gekennzeichneten Felder aus.');
        }

        if (this.emailError) {
            Renderer.renderErrorMessage('Bitte geben Sie eine gültige E-Mail-Adresse an.');
        }

        if (this.termsOfUseError) {
            Renderer.renderErrorMessage('Bitte stimmen Sie den Nutzungsbedingungen zu.');
        }
    }

    unmarkErrorInputs() {
        const inputs = DocumentSelector.selectAllByClass(ClassNames.ORDER_REQUEST_INPUT);
        inputs.forEach(input => {
            input.classList.remove(ClassNames.INPUT_ERROR);
        });

        const labels = DocumentSelector.selectAllByNode('label');
        labels.forEach(label => {
            label.classList.remove(ClassNames.LABEL_ERROR);
        });
    }

    markErrorInputs() {
        this.invalidInput.forEach(input => {
            input.classList.add(ClassNames.INPUT_ERROR);
            input.labels.forEach(label => {
                label.classList.add(ClassNames.LABEL_ERROR);
            });
        });
    }

    validateEmail() {
        const emailNode = DocumentSelector.selectById(DomIds.EMAIL);
        const email = emailNode.value;
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const error = !re.test(String(email).toLowerCase());

        if (error) {
            this.invalidInput.push(emailNode);
        }

        return error;
    }
}

export default SendOrderValidator;