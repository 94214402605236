export const Routes = {
    ELEMENT_SELECTION: 'sichtschutz/element-auswahl',
    COLOR_SELECTION: 'sichtschutz/farbauswahl',
    SUMMARY: 'sichtschutz/zusammenfassung',
    RETAILER_SEARCH: 'sichtschutz/haendler-suche',
};

export const PageTitles = {
    PREFIX: 'Karle & Rubner - Sichtschutzplaner - ',
    ELEMENT_SELECTION: 'Elementauswahl',
    COLOR_SELECTION: 'Farbauswahl',
    SUMMARY: 'Zusammenfassung',
    RETAILER_SEARCH: 'Händlersuche',
};
