import Renderer from './Renderer';
import CookieConsent from './CookieConsent';
import Wall from "./Wall";
import {DataSets, NodeNames} from "../constants/Constants";

class Ajax {
    constructor(url) {
        this.url = window.location.origin + '/' + url;
    }

    post(post, callBack, fromLoadConfiguration) {
        const formData = new FormData();
        const xhr = new XMLHttpRequest();

        for (let key in post) {
            if (post.hasOwnProperty(key)) {
                formData.append(key, JSON.stringify(post[key]));
            }
        }


        xhr.open('POST', this.url);
        xhr.onreadystatechange = function () {
            if (xhr.status === 200) {
                Renderer.renderMain(xhr.responseText);

                Wall.removeStandardElementClasses();
                if (fromLoadConfiguration !== undefined && xhr.responseText !== '') {
                    Wall.saveElements();
                    Wall.savePillarsInputs();
                }
                const cookieConsent = new CookieConsent();
                cookieConsent.run();
                if (callBack !== undefined) {
                    callBack();
                }
            } else {
                if (xhr.responseText !== '') {
                    Renderer.renderErrorMessage(JSON.parse(xhr.responseText));
                }
            }
        };
        xhr.open('post', this.url);
        xhr.send(formData);
    }
}

export default Ajax;
