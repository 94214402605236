class DecorElement {
    constructor() {
        this.decorPath = [];
        this.type = 'decor';
        this.isDoor = false;
        this.className = 'decor-item';
    }
}

export default DecorElement;
