import {ClassNames, Nodes} from '../constants/Constants';
import DocumentSelector from './DocumentSelector';
import UrlHelper from './UrlHelper';
import BrowserCheck from './BrowserCheck';

class ImageHelper {
    static getSelectedTypeImagePath(typeNumber, imageName) {
        return UrlHelper.getBaseUrl() + 'assets/images/' + imageName + 's/' + imageName + '-' + typeNumber + 'o.svg';
    }
    static getSelectedSmallTypeImagePath(typeNumber, imageName) {
        return UrlHelper.getBaseUrl() + 'assets/images/types/' + imageName + '-' + typeNumber + 'o.svg';
    }
    static getUnselectedTypeImagePath(typeNumber, imageName) {
        return UrlHelper.getBaseUrl() + 'assets/images/' + imageName + 's/' + imageName + '-' + typeNumber + '.svg';
    }
    static getUnselectedDoubleDoorImagePath(typeNumber, imageName) {
        return UrlHelper.getBaseUrl() + 'assets/images/' + imageName + 's/' + imageName + '-' + typeNumber + '.svg';
    }
    static getUnselectedSmallTypeImagePath(typeNumber, imageName) {
        return UrlHelper.getBaseUrl() + 'assets/images/types/' + imageName + '-' + typeNumber + '.svg';
    }

    static getSelectedDecorImagePath(typeNumber){
        return UrlHelper.getBaseUrl() + 'assets/images/decors/decor-' + typeNumber + 'o.png';
    }
    static getSelectedKeramikImagePath(typeNumber, length){
        return UrlHelper.getBaseUrl() + 'assets/images/keramik/keramik-'+ length+ '-' + typeNumber + 'o.png';
    }
    static getSelectedAbschlussImagePath(typeNumber, rotation){
        if(rotation) {
            return UrlHelper.getBaseUrl() + 'assets/images/abschluss/abschluss-' + typeNumber + 'o-rotated.png';
        }
        return UrlHelper.getBaseUrl() + 'assets/images/abschluss/abschluss-' + typeNumber + 'o.png';
    }

    static getUnselectedDecorImagePath(typeNumber){
        return UrlHelper.getBaseUrl() + 'assets/images/decors/decor-' + typeNumber + '.png';
    }
    static getUnselectedKeramikImagePath(typeNumber){
        return UrlHelper.getBaseUrl() + 'assets/images/keramik/keramik-' + typeNumber + '.png';
    }
    static getUnselectedAbschlussImagePath(typeNumber){
        return UrlHelper.getBaseUrl() + 'assets/images/abschluss/abschluss-' + typeNumber + '.png';
    }

    static getSelectedStandardElementImagePath(typeNumber){
        return UrlHelper.getBaseUrl() + 'assets/images/standard-elements/standard-element-' + typeNumber + '.png';
    }
    static getSelectedSmallFenceElementImagePath(typeNumber){
        return UrlHelper.getBaseUrl() + 'assets/images/small-fences/standard-fence-' + typeNumber + '.png';
    }

    static getUnselectedStandardElementImagePath(typeNumber){
        return UrlHelper.getBaseUrl() + 'assets/images/standard-elements/standard-element-' + typeNumber + '.png';
    }

    static getUnselectedSmallFenceElementImagePath(typeNumber){
        return UrlHelper.getBaseUrl() + 'assets/images/small-fences/standard-fence-' + typeNumber + '.png';
    }
    static getPreviewImagePath() {
        return UrlHelper.getBaseUrl() + 'assets/images/leeres-element.png';
    }
    static deselectAllTypeImages(imageName, className) {
        const typeImages = DocumentSelector.selectAllByClassAndChildNode(className, Nodes.IMG);

        BrowserCheck.forEachForBrowserIE();
        typeImages.forEach((element) => {
            const typeNumber = element.dataset.type;
            element.src = ImageHelper.getUnselectedTypeImagePath(typeNumber, className);
        });
    }
    static deselectAllSmallTypeImages(className) {
        const typeImages = DocumentSelector.selectAllByClassAndChildNode(className, Nodes.IMG);

        BrowserCheck.forEachForBrowserIE();
        typeImages.forEach((element) => {
            const typeNumber = element.dataset.type;
            element.src = ImageHelper.getUnselectedSmallTypeImagePath(typeNumber, className);
        });
    }

    static deselectAllDecorImages(){
        const decorImages = DocumentSelector.selectAllByClassAndChildNode(ClassNames.DECOR, Nodes.IMG);

        BrowserCheck.forEachForBrowserIE();
        decorImages.forEach((element) => {
            const typeNumber = element.dataset.decor;
            element.src = ImageHelper.getUnselectedDecorImagePath(typeNumber);
        });
    }
    static deselectAllKeramikImages(){
        const keramikImages = DocumentSelector.selectAllByClassAndChildNode(ClassNames.KERAMIK, Nodes.IMG);

        BrowserCheck.forEachForBrowserIE();
        keramikImages.forEach((element) => {
            const typeNumber = element.dataset.keramik;
            element.src = ImageHelper.getUnselectedKeramikImagePath(typeNumber);
        });
    }
    static deselectAllAbschlussImages(){
        const abschlussImages = DocumentSelector.selectAllByClassAndChildNode(ClassNames.ABSCHLUSS, Nodes.IMG);

        BrowserCheck.forEachForBrowserIE();
        abschlussImages.forEach((element) => {
            const typeNumber = element.dataset.abschluss;
            element.src = ImageHelper.getUnselectedAbschlussImagePath(typeNumber);
        });
    }

    static deselectAllStandardElementImages(){
        const standardElementImages = DocumentSelector.selectAllByClassAndChildNode(ClassNames.STANDARD_ELEMENT, Nodes.IMG);

        BrowserCheck.forEachForBrowserIE();
        standardElementImages.forEach((element) => {
            const typeNumber = element.dataset.standard;
            element.src = ImageHelper.getUnselectedStandardElementImagePath(typeNumber);
        });
    }
    static deselectAllSmallFenceElementImages(){
        const standardElementImages = DocumentSelector.selectAllByClassAndChildNode(ClassNames.SMALL_FENNCE , Nodes.IMG);

        BrowserCheck.forEachForBrowserIE();
        standardElementImages.forEach((element) => {
            const typeNumber = element.dataset.standard;
            element.src = ImageHelper.getUnselectedSmallFenceElementImagePath(typeNumber);
        });
    }
}

export default ImageHelper;
