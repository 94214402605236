import DocumentSelector from '../../helper/DocumentSelector';
import {ClassNames, DataSets, DomIds} from '../../constants/Constants';
import ToOrderButton from './ToOrderButton';

class LocationList {
    static createLocations(map) {
        DocumentSelector.selectById(DomIds.RETAILER_SEARCH_HINT).classList.add(ClassNames.HIDDEN);
        DocumentSelector.selectById(DomIds.RETAILER_SEARCH_HINT_HEADLINE).classList.remove(ClassNames.HIDDEN);
        DocumentSelector.selectById(DomIds.RETAILER_SEARCH_RESULTS).classList.remove(ClassNames.HIDDEN);
        const list = DocumentSelector.selectById(DomIds.RETAILER_SEARCH_RESULT_LIST);


        map.locations.forEach((location, index) => {
            const div = LocationList.createElement(list, 'div', '', ClassNames.RETAILER_SEARCH_RESULT_ITEM);
            div.dataset[DataSets.RETAILER_SEARCH_RESULT_ITEM] = index.toString();

            const checkboxId = 'checkbox-result-' + index;
            const checkBox = LocationList.createElement(div, 'input', '');
            checkBox.type = 'checkbox';
            checkBox.id = checkboxId;
            checkBox.className = ClassNames.RETAILER_SEARCH_RESULT_CHECKBOX;
            checkBox.dataset[DataSets.RETAILER_SEARCH_RESULT_COMPANY] = location.companyName.trim();
            checkBox.dataset[DataSets.RETAILER_SEARCH_RESULT_EMAIL] = location.email.trim();
            checkBox.dataset[DataSets.RETAILER_SEARCH_RESULT_NAME] = location.name.trim();
            checkBox.dataset[DataSets.RETAILER_SEARCH_RESULT_ZIP] = location.zip.trim();
            checkBox.dataset[DataSets.RETAILER_SEARCH_RESULT_CITY] = location.city.trim();
            checkBox.dataset[DataSets.RETAILER_SEARCH_RESULT_ADDRESS_1] = location.address_1.trim();
            checkBox.dataset[DataSets.RETAILER_SEARCH_RESULT_ADDRESS_2] = location.address_2.trim();
            checkBox.dataset[DataSets.RETAILER_SEARCH_RESULT_TELEPHONE] = location.telephone.trim();

            const label = LocationList.createElement(div, 'label', '');
            label.htmlFor = checkboxId;

            LocationList.createElement(label, 'div', location.companyName, ClassNames.RETAILER_SEARCH_RESULT_COMPANY);
            LocationList.createElement(label, 'div', location.name, '');
            LocationList.createAddress(label, location);
            LocationList.createZipAndCity(label, location);
            LocationList.createTelephoneAndMail(label, location, checkboxId);
            LocationList.createDistance(label, location);
        });

        LocationList.addEventListeners(map);
    }

    static createAddress(parent, location) {
        LocationList.createElement(parent, 'div', location.address_1.trim());
        if (location.address_2 !== '') {
            LocationList.createElement(parent, 'div', location.address_2.trim());
        }
    }

    static createZipAndCity(parent, location) {
        const result = location.zip.trim() + ', ' + location.city.trim();
        LocationList.createElement(parent, 'div', result);
    }

    static createTelephoneAndMail(parent, location) {
        const telephone = location.telephone.trim();
        const email = location.email.trim();
        if (telephone || email) {
            const container = LocationList.createElement(parent, 'div', '', ClassNames.RETAILER_SEARCH_RESULT_LINKS);

            if (telephone) {
                const telephoneResult = 'Telefon: ' + LocationList.createLink(location.telephone.trim(), 'tel:');
                LocationList.createElement(container, 'div', telephoneResult);
            }

            if (email) {
                const emailResult = 'E-Mail: ' + LocationList.createLink(location.email.trim(), 'mailto:');
                LocationList.createElement(container, 'div', emailResult);
            }
        }
    }

    static createDistance(parent, location) {
        const container = LocationList.createElement(parent, 'div', '', ClassNames.RETAILER_SEARCH_RESULT_DISTANCE);
        const result = Number(location.distance)
            .toFixed(2)
            .toString()
            .replace('.', ',')
            + ' km';
        LocationList.createElement(container, 'div', result);
    }

    static createElement(parent, tagName, innerHTML, classes) {

        const element = document.createElement(tagName);
        if (classes !== undefined) {
            element.className = classes;
        }
        element.innerHTML = innerHTML;
        parent.append(element);
        return element;
    }

    static createLink(link, linkType) {
        return '<a href="' + linkType + link + '">' + link + '</a>';
    }

    static addEventListeners(map) {
        const items = DocumentSelector.selectAllByClass(ClassNames.RETAILER_SEARCH_RESULT_ITEM);
        items.forEach((item) => {
            item.addEventListener('mouseover', (e) => {
                const index = e.target.dataset[DataSets.RETAILER_SEARCH_RESULT_ITEM];
                map.markers[index].setAnimation(google.maps.Animation.BOUNCE);
            });

            item.addEventListener('mouseleave', (e) => {
                const index = e.target.dataset[DataSets.RETAILER_SEARCH_RESULT_ITEM];
                map.markers[index].setAnimation(null);
            });



            item.addEventListener('click', (e) => {
                ToOrderButton.hideError();
            });
        });
    }
}

export default LocationList;
