import {ClassNames} from '../constants/Constants';

class Cassette {
    constructor (pos, color, visible) {
        this.pos = pos;
        this.color = color;
        this.visible = visible;
    }

    static getVisibilityClass(node) {
        const classList = node.classList;
        if (classList.contains(ClassNames.HIDDEN1)) {
            return ClassNames.HIDDEN1;
        }

        if (classList.contains(ClassNames.HIDDEN2)) {
            return ClassNames.HIDDEN2;
        }

        if (classList.contains(ClassNames.BIG)) {
            return ClassNames.BIG;
        }
        if (classList.contains(ClassNames.HIDDEN_SMALL)) {
            return ClassNames.HIDDEN_SMALL;
        }
        if (classList.contains(ClassNames.SMALLCASSETE)) {
            return ClassNames.SMALLCASSETE;
        }
    }
}

export default Cassette;
