class DocumentSelector {
    static selectByNode(node) {
        return document.querySelector(node);
    }

    static selectAllByNode(node) {
        return document.querySelectorAll(node);
    }

    static selectById(id) {
        return document.querySelector('#' + id);
    }

    static selectFirstByClass(className) {
        return document.querySelector('.' + className);
    }

    static selectAllByClass(className) {
        return document.querySelectorAll('.' + className);
    }

    static selectAllByClassAndChildNode(className, childNode) {
        return document.querySelectorAll('.' + className + ' ' + childNode);
    }
}

export default DocumentSelector;
