import DocumentSelector from '../helper/DocumentSelector';
import {ClassNames} from '../constants/Constants';
import EventHelper from '../helper/EventHelper';

class ColorSelector {
    addColorEvents() {
        const colorButtons = DocumentSelector.selectAllByClass(ClassNames.COLOR_SELECTOR);
        colorButtons.forEach((element) => {
            EventHelper.addColorClickHandler(element);
        });
    }
}

export default ColorSelector;
