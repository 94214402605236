import DocumentSelector from '../../helper/DocumentSelector';
import {ClassNames, DomIds, RetailerSearch} from '../../constants/Constants';
import Location from './Location';
import LocationList from './LocationList';
import ToOrderButton from './ToOrderButton';
import UrlHelper from '../../helper/UrlHelper';

class Map {
    constructor() {
        this.markers = [];
        this.locations = [];
        const mapNode = DocumentSelector.selectById(DomIds.RETAILER_SEARCH_MAP);
        if (mapNode) {
            const center = {lat: 50.82361901587792, lng: 8.332131673371975};
            this.map = new google.maps.Map(mapNode, {
                center: center,
                zoom: 8,
                mapTypeId: 'roadmap',
                mapTypeControlOptions: {style: google.maps.MapTypeControlStyle.DROPDOWN_MENU},
                streetViewControl: false
            });
            this.infoWindow = new google.maps.InfoWindow();

            const searchButton = DocumentSelector.selectById(DomIds.RETAILER_SEARCH_BUTTON);
            searchButton.addEventListener('click', () => {
                Map.searchLocations(this);
                ToOrderButton.hideError();
            });

            const searchInput = DocumentSelector.selectById(DomIds.RETAILER_SEARCH_ZIP);
            searchInput.addEventListener('keyup', (event) => {
                if (event.keyCode === 13) {
                    Map.searchLocations(this);
                    ToOrderButton.hideError();
                }
            });

            ToOrderButton.addEventListener();
        }
    }

    static searchLocations(map) {
        const address = DocumentSelector.selectById(DomIds.RETAILER_SEARCH_ZIP).value;
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({address: address + ' Europa'}, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
                DocumentSelector.selectById(DomIds.ERROR_CONTAINER).classList.add(ClassNames.HIDDEN);
                DocumentSelector.selectById(DomIds.RETAILER_SEARCH_ERROR_MESSAGE).classList.add(ClassNames.HIDDEN);
                Map.searchLocationsNear(results[0].geometry.location, map, address);
            } else {
                DocumentSelector.selectById(DomIds.ERROR_CONTAINER).classList.remove(ClassNames.HIDDEN);
                DocumentSelector.selectById(DomIds.RETAILER_SEARCH_ERROR_MESSAGE).classList.remove(ClassNames.HIDDEN);
            }
        });
    }

    static searchLocationsNear(center, map, address) {
        Map.clearLocations(map);
        const radius = RetailerSearch.RADIUS;
        const searchUrl = UrlHelper.generateUrl('ajax-locate-retailer.php?lat=' + center.lat() + '&lng=' + center.lng() + '&radius=' + radius + '&address=' + address);
        Map.downloadUrl(searchUrl, (data) => {
            const xml = Map.parseXml(data);
            const markerNodes = xml.documentElement.getElementsByTagName('marker');
            let bounds = new google.maps.LatLngBounds();

            if (markerNodes.length === 0) {
                DocumentSelector.selectById(DomIds.ERROR_CONTAINER).classList.remove(ClassNames.HIDDEN);
                DocumentSelector.selectById(DomIds.RETAILER_SEARCH_ERROR_MESSAGE).classList.remove(ClassNames.HIDDEN);
            }

            for (let i = 0; i < markerNodes.length; i++) {
                const latlng = new google.maps.LatLng(
                    parseFloat(markerNodes[i].getAttribute('lat')),
                    parseFloat(markerNodes[i].getAttribute('lng')));

                Map.createMarker(latlng, markerNodes[i], map);
                bounds.extend(latlng);
                map.locations.push(new Location(markerNodes[i]));
            }
            Map.createCenterMarker(center, map);
            bounds.extend(center);

            LocationList.createLocations(map);
            ToOrderButton.show(map);

            map.map.fitBounds(bounds);
            if (markerNodes.length === 0 || markerNodes.length === 1) {
                map.map.setZoom(10);
            }
            Map.calculateNodeHeight();
        });
    }

    static calculateNodeHeight() {
        const height = DocumentSelector.selectById(DomIds.RETAILER_SEARCH_FORM).clientHeight;
        DocumentSelector.selectById(DomIds.RETAILER_SEARCH_MAP).style.height = height + 'px';

    }

    static downloadUrl(url, callback) {
        var request = window.ActiveXObject ?
            new ActiveXObject('Microsoft.XMLHTTP') :
            new XMLHttpRequest;

        request.onreadystatechange = function () {
            if (request.readyState == 4) {
                request.onreadystatechange = Map.doNothing;
                callback(request.responseText, request.status);
            }
        };

        request.open('GET', url, true);
        request.send(null);
    }

    static createMarker(latlng, markerNode, map) {
        const name = markerNode.getAttribute('name');
        const address = markerNode.getAttribute('address_1');
        const companyName = markerNode.getAttribute('company_name');
        const zip = markerNode.getAttribute('zip');
        const city = markerNode.getAttribute('city');
        var html = companyName + '<br/>' + name + '<br/>' + address + '<br/>' + zip + ' ' + city;
        var marker = new google.maps.Marker({
            map: map.map,
            position: latlng,
            icon: {
                url: '/assets/images/icons/maps-marker.svg',
                scaledSize: new google.maps.Size(21, 35),
            }
        });
        google.maps.event.addListener(marker, 'click', function () {
            map.infoWindow.setContent(html);
            map.infoWindow.open(map, marker);
        });
        map.markers.push(marker);
    }

    static createCenterMarker(latlng, map) {
        var marker = new google.maps.Marker({
            map: map.map,
            position: latlng,
            icon: {
                url: '/assets/images/icons/maps-marker-center.svg',
                scaledSize: new google.maps.Size(21, 35),
            }
        });
        map.markers.push(marker);
    }

    static parseXml(str) {
        if (window.ActiveXObject) {
            var doc = new ActiveXObject('Microsoft.XMLDOM');
            doc.loadXML(str);
            return doc;
        } else if (window.DOMParser) {
            return (new DOMParser).parseFromString(str, 'text/xml');
        }
    }


    static doNothing() {
    }


    static clearLocations(map) {
        map.infoWindow.close();
        for (var i = 0; i < map.markers.length; i++) {
            map.markers[i].setMap(null);
        }
        map.markers.length = 0;
        map.locations = [];
        DocumentSelector.selectById(DomIds.RETAILER_SEARCH_RESULT_LIST).innerHTML = '';
    }
}

export default Map;