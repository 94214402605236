import DocumentSelector from '../../helper/DocumentSelector';
import {ClassNames, DomIds} from '../../constants/Constants';
import SendOrderValidator from '../validators/SendOrderValidator';
import Ajax from '../Ajax';
import Router from '../router/Router';
import {Routes} from '../router/Routes';

class OrderRequestForm {
    static addEventListeners() {
        const showTermsOfUseNode = DocumentSelector.selectById(DomIds.RETAILER_SEARCH_SHOW_TERMS_OF_USE);
        if (showTermsOfUseNode) {
            showTermsOfUseNode.addEventListener('click', OrderRequestForm.showTermsOfUse);
        }
        const closeTermsOfUseNode = DocumentSelector.selectById(DomIds.RETAILER_SEARCH_CLOSE_TERMS_OF_USE);
        if (closeTermsOfUseNode) {
            closeTermsOfUseNode.addEventListener('click', OrderRequestForm.hideTermsOfUse);
        }
        const sendOrderBtn = DocumentSelector.selectById(DomIds.RETAILER_SEARCH_SEND_ORDER);
        if (sendOrderBtn) {
            sendOrderBtn.addEventListener('click', OrderRequestForm.sendOrder);
        }

    }

    static showTermsOfUse() {
        DocumentSelector.selectById(DomIds.RETAILER_SEARCH_TERMS_OF_USE).style.display = 'block';
    }

    static hideTermsOfUse() {
        DocumentSelector.selectById(DomIds.RETAILER_SEARCH_TERMS_OF_USE).style.display = 'none';
    }

    static sendOrder(e) {
        e.preventDefault();
        const validator = new SendOrderValidator();
        const valid = validator.validateForm();
        if(!valid) {
            validator.showErrorMessages();
            validator.markErrorInputs();
        } else {
            OrderRequestForm.sendRequest();
        }
    }

    static sendRequest() {
        const data = {};
        const inputs = DocumentSelector.selectAllByClass(ClassNames.ORDER_REQUEST_INPUT);
        inputs.forEach(input => {
            data[input.id] = input.value;
        });

        const ajax = new Ajax('ajax-retailer-search.php?viewFile=sendRequest');
        ajax.post(data);
    }
}

export default OrderRequestForm;
