import DocumentSelector from './DocumentSelector';
import {ClassNames, DomIds} from "../constants/Constants";

class ScrollHelper {

    static scrollStandardElementSelectionToRightBorder(target) {
        target.addEventListener('click', this.clickScrollArrow);
    }
    static clickScrollArrow(e) {
        let container = '';
        if(e.target.classList.contains('standard-element')) {
            container = DocumentSelector.selectById('standard-element-selection');
        } else {
            container = DocumentSelector.selectById('final-selection');
        }
        const parent = container.parentElement;
        parent.scrollLeft = parent.offsetWidth;
    }
    static addEventListenerMainScrollArrow(target) {
        target.addEventListener('click', this.clickMainScrollArrow);
    }
    static clickMainScrollArrow(e) {

        const parent = DocumentSelector.selectById(DomIds.DRAWING_FIELD);
        parent.scrollLeft = parent.offsetWidth;
        const parentOfElement =  e.target.parentElement;
        const grandParent = parentOfElement.parentElement;
        grandParent.classList.add(ClassNames.HIDDEN);
        const backArrow = DocumentSelector.selectById(DomIds.ARROW_GRADIENT_ELEMENT_BACK);
        backArrow.classList.remove(ClassNames.HIDDEN);
    }
    static addEventListenerMainBackScrollArrow(target) {
        target.addEventListener('click', this.clickMainBackScrollArrow);
    }
    static clickMainBackScrollArrow(e) {

        const parent = DocumentSelector.selectById(DomIds.DRAWING_FIELD);
        parent.scrollLeft = 0;
        const backArrow = DocumentSelector.selectById(DomIds.ARROW_GRADIENT_ELEMENT_BACK);
        backArrow.classList.add(ClassNames.HIDDEN);
    }
}

export default ScrollHelper;