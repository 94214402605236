import WallElement from './WallElement';
import {ClassNames, HandlePosition} from '../constants/Constants';

class DoorElement extends WallElement {
    constructor(className, handlePosition) {
        super(className);
        this.isDoor = 'door';
        const directionClass = className.includes('double-door') ? ClassNames.DOUBLE_DOOR : '';
        this.isDoubleDoor = directionClass;
        this.handlePosition = handlePosition === undefined ? HandlePosition.RIGHT : handlePosition;
    }

    addCassette(cassette) {
        super.addCassette(cassette);
    }


    static  checkDoubleDoorDirection(objectClass, index){
        if(index === 0) {
            objectClass += ' double-door-left';
        } else {
            objectClass += ' double-door-right';
        }
        return objectClass;
    }
}
export default DoorElement;