import {ClassNames, DomIds} from '../constants/Constants';
import DocumentSelector from '../helper/DocumentSelector';
import TypeSelector from '../controller/TypeSelector';
import EventHelper from '../helper/EventHelper';
import Wall from './Wall';
import ElementLengthHelper from '../helper/ElementLengthHelper';
import BrowserCheck from '../helper/BrowserCheck';
import RetailerSearchEventHelper from '../helper/RetailerSearchEventHelper';
import ScrollHelper from '../helper/ScrollHelper';

class Renderer {
    static renderErrorMessage(message) {
        const errorNode = DocumentSelector.selectById(DomIds.ERROR_MESSAGE);
        errorNode.classList.remove(ClassNames.HIDDEN);
        errorNode.innerHTML = message;
    }

    static hideErrorMessage() {
        const errorNode = DocumentSelector.selectById(DomIds.ERROR_MESSAGE);
        errorNode.classList.add(ClassNames.HIDDEN);
        errorNode.innerHTML = '';
    }

    static renderMain(htmlString) {
        const mainNode = DocumentSelector.selectByNode(DomIds.MAIN);
        mainNode.innerHTML = htmlString;

        Renderer.setSettings();
        Renderer.addEventListeners();
        Renderer.addSummaryEventListeners();
        ElementLengthHelper.deactivateAllButOneActiveElement();
        EventHelper.selectDoorHandleAndElementWidth();
        let wallHasDoor = false;

        for (let i = 0; i < Wall.elements.length; i++) {
            if (Wall.elements[i].isDoor) {
                wallHasDoor = true;
                break;
            }
        }

        if (wallHasDoor) {
            Renderer.showDoorHandleSelection();
        } else {
            Renderer.hideDoorHandleSelection();
        }
    }

    static showDoorHandleSelection() {
        const doorHandleSelection = DocumentSelector.selectById(DomIds.DOOR_HANDLE_SELECTION);
        if (doorHandleSelection) {
            doorHandleSelection.classList.remove(ClassNames.HIDDEN);
        }
    }

    static hideDoorHandleSelection() {
        const doorHandleSelection = DocumentSelector.selectById(DomIds.DOOR_HANDLE_SELECTION);
        if (doorHandleSelection) {
            doorHandleSelection.classList.add(ClassNames.HIDDEN);
        }
    }

    static setSettings() {
        ElementLengthHelper.setCassettesWidth();
        Wall.setPresentationFieldWidth();
        BrowserCheck.forEachForBrowserIE();
    }

    static addEventListeners() {
        const lengthButtons = DocumentSelector.selectAllByClass(ClassNames.ELEMENT_LENGTH);
        const elementButtons = DocumentSelector.selectAllByClass(DomIds.CASSETTE_ITEMS);
        const elementKeramik = DocumentSelector.selectAllByClass(ClassNames.KERAMIK_ITEM);

        if (lengthButtons.length > 0 && elementButtons.length > 0 || lengthButtons.length > 0 && elementKeramik.length > 0) {
            lengthButtons.forEach((element) => {
                element.disabled = false;
                EventHelper.addElementLengthClickHandler(element);
            });
            Wall.makeRelevantLengthButtonActive();
        } else {
            lengthButtons.forEach((element) => {
                element.disabled = true;
            });
        }

        const presentationField = DocumentSelector.selectById(DomIds.DRAWING_FIELD);
        if (presentationField && presentationField.scrollWidth > presentationField.offsetWidth) {
            EventHelper.scrollToActiveElement();
            EventHelper.scrollToPreviewImage(presentationField);
        }

        const elementSelection = DocumentSelector.selectById(DomIds.ELEMENT_SELECTOR);
        if (elementSelection) {
            const typeSelector = new TypeSelector();
            let shield = DocumentSelector.selectById('shield-elements');
            let decor = DocumentSelector.selectById('decor-elements');
            let length = DocumentSelector.selectById('length-selector');

            elementSelection.onchange = function () {
                if (elementSelection.options[elementSelection.selectedIndex].value === 'shields') {
                    if (shield.classList.contains('hidden')) {
                        shield.classList.remove('hidden');
                        if (length) {
                            length.classList.remove('hidden');
                        }
                    }

                    if (!decor.classList.contains('hidden')) {
                        decor.classList.add('hidden');
                    }

                    typeSelector.addTypeEvents();
                } else if (elementSelection.options[elementSelection.selectedIndex].value === 'decor') {
                    if (decor.classList.contains('hidden')) {
                        decor.classList.remove('hidden');
                        if (length) {
                            length.classList.add('hidden');
                        }
                    }

                    if (!shield.classList.contains('hidden')) {
                        shield.classList.add('hidden');
                    }
                    typeSelector.addDecorEvents();
                }
            };
        }

        const elementTabs = DocumentSelector.selectById(DomIds.ELEMENT_TABS);
        if (elementTabs) {
            const typeSelector = new TypeSelector();
            const shield = DocumentSelector.selectById('shield-elements');
            const standard = DocumentSelector.selectById('standard-elements');
            const door = DocumentSelector.selectById('door-elements');
            const decor = DocumentSelector.selectById('decor-elements');
            const abschluss = DocumentSelector.selectById('final-elements');
            const keramik = DocumentSelector.selectById('keramik-elements');

            if (shield) {
                shield.addEventListener('click', typeSelector.addTypeEvents());
            }

            if (door) {
                shield.addEventListener('click', typeSelector.addDoorEvents());
            }

            if (standard) {
                standard.addEventListener('click', typeSelector.addStandardTypeEvents());
            }
            if (keramik) {
                keramik.addEventListener('click', typeSelector.addKeramikEvents());
            }
            if (decor) {
                decor.addEventListener('click', typeSelector.addDecorEvents());
            }
            if (abschluss) {
                abschluss.addEventListener('click', typeSelector.addAbschlussEvents());
            }
        }

        const elementPlusButtons = DocumentSelector.selectAllByClass(ClassNames.ELEMENT_PLUS_BUTTON);
        if (elementPlusButtons.length > 0) {
            elementPlusButtons.forEach((element) => {
                element.addEventListener('click', EventHelper.clickPlusConfirm);
            });
        }

        const decorPlusButtons = DocumentSelector.selectAllByClass(ClassNames.DECOR_PLUS_BUTTON);
        if (decorPlusButtons.length > 0) {
            decorPlusButtons.forEach((element) => {
                element.addEventListener('click', EventHelper.clickPlusDecorConfirm);
            });
        }

        const minusButtons = DocumentSelector.selectAllByClass(ClassNames.MINUS_BUTTON);
        if (minusButtons.length > 0) {
            minusButtons.forEach((element) => {
                element.addEventListener('click', EventHelper.clickMinusConfirm);
            });
        }

        const elementCopyButtons = DocumentSelector.selectAllByClass(ClassNames.ELEMENT_COPY_BUTTON);
        if (elementCopyButtons.length > 0) {
            elementCopyButtons.forEach((element) => {
                element.addEventListener('click', EventHelper.clickCopyConfirm);
            });
        }
        const keramikCopyButtons = DocumentSelector.selectAllByClass(ClassNames.KERAMIK_COPY_BUTTON);
        if (keramikCopyButtons.length > 0) {
            keramikCopyButtons.forEach((element) => {
                element.addEventListener('click', EventHelper.clickCopyKeramikConfirm);
            });
        }


        const decorCopyButtons = DocumentSelector.selectAllByClass(ClassNames.DECOR_COPY_BUTTON);
        if (decorCopyButtons.length > 0) {
            decorCopyButtons.forEach((element) => {
                element.addEventListener('click', EventHelper.clickDecorCopyConfirm);
            });
        }

        const overlayButtons = DocumentSelector.selectAllByClass(ClassNames.OVERLAY);
        overlayButtons.forEach((element) => {
            EventHelper.designOfFreeWallElement(element);
        });

        const decorConfirmButton = DocumentSelector.selectAllByClass(DomIds.DECOR_CONFIRM_BUTTON);
        decorConfirmButton.forEach((element) => {
            EventHelper.addConfirmDecorClickHandler(element);
        });

        const typeSelectorNode = DocumentSelector.selectById(DomIds.TYPE_SELECTOR);
        if (typeSelectorNode) {
            const typeSelector = new TypeSelector();
            typeSelector.addTypeEvents();
            typeSelector.addConfirmEvent();
        }

        const cassetteButtons = DocumentSelector.selectAllByClass(ClassNames.WALL_ELEMENT);
        cassetteButtons.forEach((element) => {
            EventHelper.changeColorClickHandler(element);
        });
        const keramikElement = DocumentSelector.selectAllByClass(ClassNames.KERAMIK_ITEM);
        keramikElement.forEach((element) => {
            EventHelper.selectKeramik(element);
        });
        const cassetteButtons2 = DocumentSelector.selectAllByClass(ClassNames.WALL_ELEMENT_SMALL);
        cassetteButtons2.forEach((element) => {
            EventHelper.changeColorClickHandler(element);
        });
        const planBreakButton = DocumentSelector.selectById(DomIds.BREAK_BUTTON);
        if (planBreakButton) {
            planBreakButton.addEventListener('click', EventHelper.clickPlanBreak);
        }

        const newPlanButton = DocumentSelector.selectById(DomIds.NEW_PLAN_BUTTON);
        if (newPlanButton) {
            newPlanButton.addEventListener('click', EventHelper.clickPlanBreak);
        }

        const planEndButton = DocumentSelector.selectById(DomIds.END_BUTTON);
        if (planEndButton && planEndButton.classList.contains('plan-decision')) {
            planEndButton.addEventListener('click', EventHelper.clickEndButton);
        }

        const planEndButtonEndkunde = DocumentSelector.selectById(DomIds.END_BUTTON_ENDKUNDE);
        if (planEndButtonEndkunde && planEndButtonEndkunde.classList.contains('plan-decision')) {
            planEndButtonEndkunde.addEventListener('click', EventHelper.clickEndButtonEndkunde);
        }

        const backToPlan = DocumentSelector.selectById(DomIds.TO_PLAN);
        if (backToPlan) {
            backToPlan.addEventListener('click', EventHelper.clickToPlan);
        }

        const toOffer = DocumentSelector.selectById(DomIds.TO_OFFER);
        if (toOffer) {
            toOffer.addEventListener('click', EventHelper.clickToOffer);
        }

        const toSummary = DocumentSelector.selectById(DomIds.TO_SUMMARY);
        if (toSummary) {
            toSummary.addEventListener('click', EventHelper.clickToSummary);
        }

        const anchorButtons = DocumentSelector.selectAllByClass(ClassNames.POST_ANCHOR);
        if (anchorButtons.length > 0) {
            anchorButtons.forEach((element) => {
                element.addEventListener('click', EventHelper.clickAnchor);
            });
        }

        const pillarsColor = document.querySelectorAll('input[name = "postColor"]');
        if (pillarsColor) {
            pillarsColor.forEach((element) => {
                element.addEventListener('click', EventHelper.clickPillarsColor);
            });
        }

        const profileColor = document.querySelectorAll('input[name = "profile-color"]');
        if (profileColor) {
            profileColor.forEach((element) => {
                element.addEventListener('click', EventHelper.clickProfileColor);
            });
        }

        const pillarsCap = DocumentSelector.selectById('coveringCaps');
        if (pillarsCap) {
            pillarsCap.addEventListener('click', EventHelper.clickPillarsCap);
        }

        const pillarsChosenColor = DocumentSelector.selectById('pillarsColor');
        if (pillarsChosenColor) {
            let chosenColor = pillarsChosenColor.innerHTML;
            Wall.setPillarColor(chosenColor);
        }

        const logout = DocumentSelector.selectById(DomIds.LOGOUT_ROW);
        const password = DocumentSelector.selectById('password');

        if (!password) {
            logout.classList.remove('hidden');
        } else {
            logout.classList.add('hidden');
        }
        const breakButton = document.getElementById('breakbutton');
        if (breakButton !== null) {
            breakButton.addEventListener('click', EventHelper.clickBreak);
        }
        if (!EventHelper.areElementPreviewImagesInactive()) {
            EventHelper.changeCursorOfWallElementsToDefault();
        }

        const doorHandleLeft = DocumentSelector.selectById(DomIds.DOOR_HANDLE_LEFT_RADIO);
        const doorHandleRight = DocumentSelector.selectById(DomIds.DOOR_HANDLE_RIGHT_RADIO);
        if (doorHandleLeft) {
            EventHelper.addDoorHandleClickHandler(doorHandleLeft);
        }

        if (doorHandleRight) {
            EventHelper.addDoorHandleClickHandler(doorHandleRight);
        }
        const arrowButton = document.getElementsByClassName('scroll-arrow');
        if (arrowButton.length > 0) {
            arrowButton.forEach((element) => {
                ScrollHelper.scrollStandardElementSelectionToRightBorder(element);
            });
        }
        let mainTab = document.getElementById(DomIds.DRAWING_FIELD);
        const scrollElement = DocumentSelector.selectById(DomIds.SCROLL_MAIN_TAB_FORWARD);
        const scrollElement2 = DocumentSelector.selectById(DomIds.SCROLL_MAIN_TAB_BACK);
        const arrowElement = DocumentSelector.selectById(DomIds.ARROW_GRADIENT_ELEMENT_FORWARD);
        if (mainTab) {
            mainTab.addEventListener('scroll', (event) => {
                if (arrowElement && event.target.scrollLeft === 0) {
                    arrowElement.classList.remove(ClassNames.HIDDEN);
                }
            });
            if (mainTab.scrollWidth > mainTab.clientWidth) {
                ScrollHelper.addEventListenerMainBackScrollArrow(scrollElement2);
                if (arrowElement && arrowElement.classList.contains(ClassNames.HIDDEN)) {
                    arrowElement.classList.remove(ClassNames.HIDDEN);
                }
                ScrollHelper.addEventListenerMainScrollArrow(scrollElement);
            } else if (arrowElement) {
                arrowElement.classList.add(ClassNames.HIDDEN);
            }
        }


    }

    static addSummaryEventListeners() {
        const retailerSearch = DocumentSelector.selectById(DomIds.RETAILER_SEARCH);
        if (retailerSearch) {
            retailerSearch.addEventListener('click', RetailerSearchEventHelper.goToRetailerSearch);
        }
    }

}

export default Renderer;
