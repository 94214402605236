import {HandlePosition} from '../constants/Constants';

class WallElement {
    constructor(className) {
        this.cassettes = [];
        this.type = 'element';
        this.className = className;
        this.isDoor = false;
        this.isDoubleDoor = false;
        this.handlePosition = HandlePosition.RIGHT;
    }

    addCassette(cassette) {
        this.cassettes.push(cassette);
    }
}

export default WallElement;
