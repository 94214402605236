import DocumentSelector from '../../helper/DocumentSelector';
import {ClassNames, DataSets, DomIds} from '../../constants/Constants';
import Ajax from '../Ajax';
import OrderRequestForm from "./OrderRequestForm";

class ToOrderButton {
    static show() {
        const btn = DocumentSelector.selectById(DomIds.RETAILER_SEARCH_TO_ORDER);
        btn.classList.remove(ClassNames.HIDDEN);
    }

    static showError() {
        DocumentSelector.selectById(DomIds.RETAILER_SEARCH_ERROR_NO_ONE_SELECTED).classList.remove(ClassNames.HIDDEN);
        DocumentSelector.selectById(DomIds.ERROR_CONTAINER).classList.remove(ClassNames.HIDDEN);
    }

    static hideError() {
        DocumentSelector.selectById(DomIds.RETAILER_SEARCH_ERROR_NO_ONE_SELECTED).classList.add(ClassNames.HIDDEN);
        DocumentSelector.selectById(DomIds.ERROR_CONTAINER).classList.add(ClassNames.HIDDEN);
    }

    static addEventListener() {
        const btn = DocumentSelector.selectById(DomIds.RETAILER_SEARCH_TO_ORDER);
        btn.addEventListener('click', ToOrderButton.goToOrderRequest);
    }

    static goToOrderRequest() {
        let valid = false;
        const checkBoxes = DocumentSelector.selectAllByClass(ClassNames.RETAILER_SEARCH_RESULT_CHECKBOX);
        const emailAddresses = [];
        const companyName = [];
        const name = [];
        const zip = [];
        const city = [];
        const address1 = [];
        const address2 = [];
        const telephone = [];

        checkBoxes.forEach(checkBox => {
            if (checkBox.checked) {
                valid = true;
                emailAddresses.push(checkBox.dataset[DataSets.RETAILER_SEARCH_RESULT_EMAIL]);
                companyName.push(checkBox.dataset[DataSets.RETAILER_SEARCH_RESULT_COMPANY]);
                name.push(checkBox.dataset[DataSets.RETAILER_SEARCH_RESULT_NAME]);
                zip.push(checkBox.dataset[DataSets.RETAILER_SEARCH_RESULT_ZIP]);
                city.push(checkBox.dataset[DataSets.RETAILER_SEARCH_RESULT_CITY]);
                address1.push(checkBox.dataset[DataSets.RETAILER_SEARCH_RESULT_ADDRESS_1]);
                address2.push(checkBox.dataset[DataSets.RETAILER_SEARCH_RESULT_ADDRESS_2]);
                telephone.push(checkBox.dataset[DataSets.RETAILER_SEARCH_RESULT_TELEPHONE]);
            }
        });

        if (valid) {
            ToOrderButton.hideError();
            const ajax = new Ajax('ajax-retailer-search.php?viewFile=toOrderRequest');
            ajax.post({emailAddresses, companyName, name, zip, city, address1, address2, telephone}, () => {
                OrderRequestForm.addEventListeners();
            });
        } else {
            ToOrderButton.showError();
        }
    }
}

export default ToOrderButton;
